<template>
  <div class="wrapper">
    <span class="header">{{ this.$t("jackpotRules") }}</span>
    <div class="rule" :key="rule.id" v-for="rule in rules">
      <span class="sub_header3">{{ rule.subtitle3 }}</span>
      <span class="sub_header">{{ rule.subtitle1 }}</span>
      <p v-html="formatText(rule.label)"></p>
      <span class="sub_header2">{{ rule.subtitle2 }}</span>
      <span class="sub_header3">{{ rule.subtitle4 }}</span>
      <p v-html="formatText(rule.label2)"></p>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: "Rules",
  methods: {
    formatText(text) {
      return text.replace(/\/n/g, "<br><br>");
    },
  },
  computed: {
    rules() {
      return [
        {
          id: 1,
          title: this.$t("jackpotTitle10"),
          subtitle1: this.$t("jackpotTitle10"),
          subtitle2: this.$t("online"),
          label: this.$t("jackpotDescription"),
          label2: this.$t("betSelectionProcess"),
        },
      ];
    },
  },
};
</script>

<style src="./index.css" scoped></style>
