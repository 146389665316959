<template>
  <div>
    <button class="backwrap" style="color: #000" @click="goBack()">
      <BackArrow />
      <h3 class="title" style="color: var(--text-color)">{{ title }}</h3>
    </button>
  </div>
</template>

<script>
import BackArrow from "../../components/icons/BackArrow.vue";
export default {
  name: "BackComponent",
  props: {
    title: {
      type: String,
      required: true,
    },
    goto: {
      type: String,
      required: false,
    },
    closeBet: {
      type: Boolean,
      required: false,
    },
    disable: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    BackArrow,
  },
  methods: {
    goBack() {
      if (this.disable) {
        return;
      }
      if (this.closeBet) {
        this.closeBetSlip();
        return;
      }
      if (this.goto) {
        this.$router.push(this.goto);
      } else {
        this.$router.go(-1);
      }
    },

    closeBetSlip() {
      document.getElementById("showBetSlip").style.display = "none";
    },
  },
};
</script>

<style scoped>
.backwrap {
  border: none;
  background-color: transparent;
  height: 44px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.title {
  font-weight: 600;
}
h3 {
  color: var(--text-color);
  text-align: center;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 0;
}
svg {
  /* margin-top: -4px; */
}
</style>
