import axios from "axios";

const bettingserve = axios.create({
  baseURL: process.env.VUE_APP_BASE_BETTING_URL,
});

// Set default headers for POST requests
bettingserve.defaults.headers.post["Content-Type"] = "application/json";
bettingserve.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

bettingserve.interceptors.request.use(
  (config) => {
    const lang = localStorage.getItem("locale") || "en";
    config.headers["lang"] = lang;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default bettingserve;
