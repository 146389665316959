<template>
  <div class="success">
    <div v-if="type === 'success'">
      <SuccessfullIcon />
    </div>
    <div v-if="type === 'pending'">
      <SuccessIcon />
    </div>
    <h3>{{ title }}</h3>
    <p>
      {{ description }}
    </p>
  </div>
</template>

<script>
import SuccessIcon from "../icons/SuccessIcon.vue";
import SuccessfullIcon from "../icons/SuccessfullIcon.vue";

export default {
  name: "SuccessCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: "success",
      // success or pending
    },
  },
  components: {
    SuccessIcon,
    SuccessfullIcon,
  },
};
</script>

<style scoped>
.success {
  font-family: "Satoshi";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.12px;
  display: flex;
  padding-top: 1rem;
  flex-direction: column;
  text-align: center;
}

.success img {
  margin-bottom: 3rem;
}
.success svg {
  margin: 0 auto;
  margin-bottom: 16px;
}
.success p {
  /* color: #545151; */
  width: 80%;
  margin: 0 auto;
  margin-bottom: 20px;
  color: var(--text-color);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.success h3 {
  /* color: #0e0d0d; */
  color: var(--text-color);
  text-align: center;
  font-family: "Satoshi";
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.14px;
}
</style>
