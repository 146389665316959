<template>
  <div class="wrapper">
    <div>
      <!-- <div class="page_container">
        <h6>Results</h6>
      </div> -->
      <!-- <div class="select-sport">
        <button
          :class="{ active: activeCategory === 'sports' }"
          @click="setCategory('sports')"
        >
          Results
        </button>
        <button
          :class="{ active: activeCategory === 'casino' }"
          @click="setCategory('casino')"
        >
          Highlight
        </button>
      </div> -->
      <div>
        <div class="widgets">
          <iframe :src="statUrl" frameborder="0"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Result",
  components: {},
  data() {
    return {
      isPeriodUpOpen: false,
      isLeaderboardOpen: false,
      fiatCurrency: process.env.VUE_APP_CURRENCY,
      activeCategory: "sports",
      activeTab: "points",
      activeLeaderboard: "Local Leaderboard",
      statUrl: `https://statshub.sportradar.com/choplifegamingmts/fr/sport/1`,
      loading: true,
    };
  },
  methods: {
    handleLeaderboard(type) {
      this.activeLeaderboard = type.name;
      this.closeLeaderboardUp();
    },
    openLeaderboardUp() {
      this.isLeaderboardOpen = true;
    },
    closeLeaderboardUp() {
      this.isLeaderboardOpen = false;
    },
    openPeriod() {
      this.isPeriodUpOpen = true;
    },
    closePeriodUp() {
      this.isPeriodUpOpen = false;
    },
    setActive(tab) {
      this.activeTab = tab;
    },
    setCategory(category) {
      this.activeCategory = category;
    },
  },
  computed: {
    filteredLeaderboard() {
      if (this.activeTab === "points") {
        return this.leaderboardData;
      }
      return this.leaderboardData.map((entry) => ({
        ...entry,
        points: this.activeTab === "rules" ? "N/A" : entry.points,
        cash: this.activeTab === "period" ? "N/A" : entry.cash,
      }));
    },
  },
  mounted() {},
};
</script>

<style scoped src="./index.css"></style>
