var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isVisible)?_c('div',[_c('div',{class:[
      { 'full-modal': _vm.disableClose },
      _vm.isVisible && _vm.modal ? 'slide_wrapper modal' : 'slide_wrapper',
    ]},[_c('div',{staticClass:"click_away",on:{"click":function($event){return _vm.closeSlideUp()}}}),_c('div',{class:_vm.isVisible ? 'slide_container' : 'slide_container_up',style:({
        borderRadius: _vm.fullHeight ? '0px' : '16px',
      })},[_c('div',{staticClass:"scrollable_contents",style:({
          maxHeight: _vm.fullHeight ? '' : '85',
          minHeight: _vm.fullHeight ? '100' : '',
        })},[_vm._t("default")],2)])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }