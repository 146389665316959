export const  sportData =  [
      { sport_id: "1", sport_name: "Soccer" },
      {
        sport_id: "2",
        sport_name: "Basketball",
      },
      { sport_id: "3", sport_name: "Baseball" },
      { sport_id: "4", sport_name: "Ice Hockey" },
      {
        sport_id: "5",
        sport_name: "Tennis",
      },
      { sport_id: "6", sport_name: "Handball" },
      { sport_id: "7", sport_name: "Floorball" },
      {
        sport_id: "9",
        sport_name: "Golf",
      },
      { sport_id: "10", sport_name: "Boxing" },
      { sport_id: "11", sport_name: "Motorsport" },
      {
        sport_id: "12",
        sport_name: "Rugby",
      },
      { sport_id: "13", sport_name: "Aussie Rules" },
      { sport_id: "15", sport_name: "Bandy" },
      {
        sport_id: "16",
        sport_name: "American Football",
      },
      { sport_id: "17", sport_name: "Cycling" },
      { sport_id: "19", sport_name: "Snooker" },
      {
        sport_id: "20",
        sport_name: "Table Tennis",
      },
      { sport_id: "21", sport_name: "Cricket" },
      { sport_id: "22", sport_name: "Darts" },
      {
        sport_id: "23",
        sport_name: "Volleyball",
      },
      { sport_id: "24", sport_name: "Field hockey" },
      { sport_id: "26", sport_name: "Waterpolo" },
      {
        sport_id: "29",
        sport_name: "Futsal",
      },
      { sport_id: "31", sport_name: "Badminton" },
      { sport_id: "32", sport_name: "Bowls" },
      {
        sport_id: "34",
        sport_name: "Beach Volley",
      },
      { sport_id: "37", sport_name: "Squash" },
      { sport_id: "38", sport_name: "Rink Hockey" },
      {
        sport_id: "39",
        sport_name: "Lacrosse",
      },
      { sport_id: "43", sport_name: "Alpine Skiing" },
      { sport_id: "44", sport_name: "Biathlon" },
      {
        sport_id: "46",
        sport_name: "Cross-Country",
      },
      { sport_id: "47", sport_name: "Nordic Combined" },
      {
        sport_id: "48",
        sport_name: "Ski Jumping",
      },
      { sport_id: "49", sport_name: "Snowboard" },
      {
        sport_id: "50",
        sport_name: "Speed Skating",
      },
      { sport_id: "51", sport_name: "Luge" },
      { sport_id: "60", sport_name: "Beach Soccer" },
      {
        sport_id: "61",
        sport_name: "Pesapallo",
      },
      { sport_id: "109", sport_name: "Counter-Strike" },
      {
        sport_id: "110",
        sport_name: "League of Legends",
      },
      { sport_id: "111", sport_name: "Dota 2" },
      { sport_id: "117", sport_name: "MMA" },
      {
        sport_id: "118",
        sport_name: "Call of Duty",
      },
      { sport_id: "121", sport_name: "Overwatch" },
      {
        sport_id: "125",
        sport_name: "Rainbow Six",
      },
      { sport_id: "131", sport_name: "Speedway" },
      {
        sport_id: "147",
        sport_name: "Drag Racing",
      },
      { sport_id: "191", sport_name: "Stock Car Racing" },
    ];