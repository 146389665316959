<template>
  <div class="wrapper" style="">
    <h2 class="text-dark">Chopmaster</h2>
  </div>
</template>

<script>
export default {
  name: "Chopmaster",
  components: {},
};
</script>

<style scoped></style>
