<template>
  <div class="promo-wrapper">
    <div class="back" @click="closePromotion">
      <BackArrow />
    </div>
    <div class="promotion-detail">
      <div class="card">
        <div class="card-top">
          <img :src="getImageSrc(promotion.img)" alt="Promotion Image" />
        </div>
        <div class="card-center">
          <span>{{ $t(promotion.category) }}</span>
        </div>
        <div class="content">
          <p class="content-detail">{{ $t(promotion.details) }}</p>
        </div>
      </div>
    </div>
    <div class="howtoparticipate">
      <div class="heading">
        <p>{{ $t("howToParticipate") }}</p>
      </div>
      <div class="steps">
        <div v-for="(step, index) in promotion.steps" :key="index">
          <p>{{ $t(`step`, { number: index + 1 }) }}</p>
          <p>{{ $t(step.step) }}</p>
        </div>
      </div>

      <div
        v-if="promotion.key === 'freebet'"
        class="btn"
        @click="handleRegister"
      >
        <ChopbetButton :size="'small'">{{ $t("playGames") }}</ChopbetButton>
      </div>
      <div
        v-else-if="promotion.category === 'casino'"
        class="btn"
        @click="handlePlayGame"
      >
        <ChopbetButton :size="'small'">{{ $t("playGames") }}</ChopbetButton>
      </div>
      <div v-else class="btn" @click="handleRegister">
        <ChopbetButton :size="'small'">{{
          isLoggedIn ? $t("depositNow") : $t("registerNow")
        }}</ChopbetButton>
      </div>
    </div>
    <div class="termsandcondition">
      <p>{{ $t("termsConditions") }}</p>
      <span v-html="formatText($t(promotion.termsAndCondition))"></span>
    </div>
  </div>
</template>

<script>
import ChopbetButton from "../../components/ui/ChopbetButton.vue";
import BackArrow from "../../components/icons/BackArrow.vue";

export default {
  name: "GiftDetail",
  // props: {
  //   promotion: {
  //     type: Object,
  //     required: true,
  //   },
  // },
  components: { ChopbetButton, BackArrow },
  data() {
    return {
      myProfile: this.getProfile(),
      steps: ["step1", "step2", "step3"],
    };
  },
  computed: {
    promotions() {
      return this.$store.state.promotions;
    },
    promotion() {
      const filterPromo = this.promotions.filter(
        (item) => item.id === +this.$route.params.id
      );
      return filterPromo[0];
    },
    profile: function () {
      return this.myProfile;
    },
    isLoggedIn: function () {
      var p = this.getProfile();
      if (!p) {
        return false;
      }
      return true;
    },
    currentLanguage() {
      return this.$i18n.locale;
    },
  },
  methods: {
    closePromotion() {
      this.$router.push(`/gifts`);
    },
    getImageSrc(image) {
      return image[this.currentLanguage] || image.fr;
    },
    routeText(text) {
      return text.replace(
        /\[link\](.*?)\[\/link\]/g,
        `<router-link to="/join">$1</router-link>`
      );
    },
    formatText(text) {
      return text && text.replace(/\/n/g, "<br>");
    },
    handleRegister() {
      this.$router.push(this.isLoggedIn ? this.promotion.route : "/join");
    },
    handlePlayGame() {
      if (this.promotion.route) {
        this.$router.push(this.promotion.route);
        return;
      }
    },
  },
  mounted: function () {
    this.myProfile = this.getProfile();
    window.scrollTo(0, 0);
    // const filterPromo = this.promotions.filter(
    //   (item) => item.id === +this.$route.params.id
    // );
    console.log(
      "promotionspromotionspromotionspromotionspromotions",
      this.currentLanguage
    );
  },
  watch: {
    currentLanguage() {
      console.log(this.$store.state.promotions);
    },
  },
};
</script>

<style scoped src="./index.css"></style>
