<template>
  <div>
    <BottomNavigationMenu
      v-bind="{
        isLoggedIn: isLoggedIn,
        normalBetsCount: normalBetsCount,
        betslip_count: betslip_count,
        liveGames: liveGames,
      }"
    >
    </BottomNavigationMenu>

    <span id="open-sharebet" class="hidden"></span>
    <span id="betslip-init" class="hidden"></span>
  </div>
</template>

<script>
import axios from "@/services/api";
import fix from "@/services/fix";
import { pushToDataLayer } from "@/utils/gtm";
import bettingserve from "@/services/bettingserve";
// import BetslipModalToggle from "@/components/betslip/BottomModalToggle";
// import BetslipSummary from "@/components/betslip/Summary";
import BottomNavigationMenu from "@/components/BottomNavigationMenu";
export default {
  name: "bottom-navigation",
  props: {
    page: {
      required: false,
    },
  },

  data: function () {
    return {
      stake: 50,
      loading: "",
      bestlip_visible: false,
      code: "",
      msg: "",
      share_odds: "",
      copyText: "Copy Link",
      copyBookingCode: "Click to copy",
      myProfile: this.getProfile(),
      accept_odds_changes: true,
      previous_odds: 0,
      odds_changed: false,
      seen: false,
      withholding_tax: 0,
      booking_code: "",
      can_get_bonus: false,
      normalBetsCount: 0,
      total: 0,
      showSuccessModal: false,
      shareMyBet: false,
      btnMinus: "/img/icons/minus.png",
      btnPlus: "/img/icons/plus.png",
    };
  },
  mounted: function () {
    this.fetchNormalBetsCount();
    this.setValue("stake", 50);
    this.initBetslipModal();
    this.initSharebetModal();
    this.previous_odds = this.betslip.odds;
    console.log(this.betslip);

    this.$store.dispatch("getLiveMatchCounter");
    var vm = this;
    if (this.$store.state.placeBet == 1) {
      vm.showBetslip();
      this.$store.dispatch("setPlaceBet", 0);
    }
    console.log("VM Store Place Bet:" + this.$store.state.placeBet);
    this.EventBus.$on("event:betslip:show", function () {
      vm.showBetslip();
    });
    this.EventBus.$on("event:betslip:hide", function () {
      vm.hideBetslip();
    });
    this.EventBus.$on("event:betslip:placeBet", function () {
      vm.placeBet(true);
    });
    this.EventBus.$on("profile:balance", function (payload) {
      vm.myProfile = payload;
    });
    this.EventBus.$on("share:bet", function (payload) {
      vm.code = payload.code;
      vm.msg = payload.message;
      vm.share_odds = payload.odds;
      vm.shareBetMessage();
      document.getElementById("open-sharebet").click();
    });
    this.EventBus.$on("odds:changed", function (payload) {
      vm.odds_changed = true;
      vm.updateOdd(
        payload.odd_id,
        payload.odds,
        payload.previous_odds,
        payload.active,
        payload.status
      );
    });
  },
  methods: {
    closeBetSlip() {
      document.getElementById("showBetSlip").style.display = "none";
    },
    setAmount: function (amountToAdd) {
      this.stake = parseFloat(amountToAdd).toFixed(2);
    },
    fetchNormalBetsCount() {
      var vm = this;

      const path = process.env.VUE_APP_MY_BET_COUNT;
      axios
        .get(path, {
          headers: {
            "Content-Type": "application/json",
            "api-key": vm.getAuth(),
          },
        })
        .then((response) => {
          this.normalBetsCount = response.data.total;
          console.log(
            "Success fetching normal bets count:",
            response.data.total
          );
        })
        .catch((err) => {
          console.error("Error fetching normal bets count:", err);
        });
    },
    checkLetters: function () {
      //
    },
    loadBookingCode: function () {
      if (this.booking_code.trim().length > 4) {
        if (this.currentRouteName !== "share") {
          this.$router.push({
            name: "share",
            params: { code: this.booking_code.trim() },
          });
        } else {
          this.EventBus.$emit("share:code", this.booking_code.trim());
        }
      } else {
        this.setError("Please enter a valid booking code to proceed");
      }
    },
    shareBetMessage: function () {
      var odds = this.share_odds;
      var toWin = this.formatCurrency(parseFloat(odds) * this.stake);
      this.msg = this.msg.replace("{possible_win}", toWin);
      this.msg = this.msg.replace("{line}", "");
      this.msg = this.msg.replace("{line}", "");
      this.msg = this.msg.replace("{line}", "");
      this.msg = this.msg.replace("{line}", "");
      this.msg = this.msg.replace("{stake}", this.stake);
    },
    formatOdds: function (x) {
      if (x === undefined) {
        return 1;
      }
      return parseFloat(x).toFixed(2);
    },
    bonusUpdate: function () {
      if (this.can_get_bonus) {
        this.can_get_bonus = false;
      } else {
        this.can_get_bonus = true;
        this.stake = this.myProfile.b2;
      }
    },
    getSportAlt: function (sport_id) {
      switch (parseInt(sport_id)) {
        case 1:
          return "Soccer Icon";
        case 2:
          return "basketball icon";
        case 4:
          return "hockey icon";
        case 5:
          return "tennis icon";
        case 22:
          return "/assets/icons/dart.svg";
        case 20:
          return "/assets/icons/tabletennis.svg";
        case 6:
          return "/assets/icons/handball.svg";
        case 12:
          return "/assets/icons/rugby.svg";
        case 10:
          return "/assets/icons/boxing.svg";
        case 31:
          return "/assets/icons/badminton.svg";
        case 3:
          return "/assets/icons/baseball.svg";
      }
      return "";
    },
    show: function () {
      this.jQuery("#betslip-modal").modal("show");
      this.bestlip_visible = true;
    },
    tax: function () {
      //
    },

    handlePlaceBetClick(event) {
      this.placeBet(event);
    },
    trackPlaceBetSuccessfulButtonClick(event) {
      pushToDataLayer("gtm.betPlacedSuccessful", {
        category: "Button",
        action: "Click",
        label: "betPlacedSuccessful",
        element: event.target,
      });
    },
    trackPlaceBetUnsuccessfulButtonClick(event) {
      pushToDataLayer("gtm.BetPlaceUnsuccessful", {
        category: "Button",
        action: "Click",
        label: "BetPlaceUnsuccessful",
        element: event.target,
      });
    },

    placeBet: function (event) {
      this.reset();
      var p = this.getProfile();
      if (!p) {
        this.setError(`${this.$t("pleaseLoginProceed")}`);
        this.setValue("placeBet", 1);
        this.$router.push({ name: "login", params: {} });
        return;
      }
      var bet_amount = this.getValue("stake");
      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      if (bet_amount < 10) {
        this.setError("Please enter bet amount greater of 10 CFA. or more");
        return;
      }
      var betslipData = this.betslip;
      if (betslipData.total === 0) {
        this.setError("Please Select at least one outcome to continue");
        return;
      }
      if (!this.accept_odds_changes && this.odds_changed) {
        this.setError(`${this.$t("acceptOddsChangesProceed")}`);
        return;
      }
      var bets = [];
      this.jQuery.each(betslipData.picks, function (k, v) {
        bets.push({
          market_id: parseInt(v.market_id),
          match_id: parseInt(v.match_id),
          outcome_id: v.outcome_id,
          producer_id: 3,
          specifier: v.specifier,
        });
      });
      console.log(this.getAuth());
      var data = {
        bet_type: parseInt(1),
        bets: bets,
        booking_code: "",
        campaign: String(utm_campaign),
        ip_address: "",
        medium: String(utm_medium),
        source: this.isMobile() ? 2 : 1,
        stake: parseInt(bet_amount),
        stake_type: this.can_get_bonus ? 2 : 1,
        utm_source: String(utm_source),
        referrer: document.referrer,
      };
      console.log(this.getAuth());
      this.loading = "loading";
      var vm = this;
      var path = process.env.VUE_APP_BASE_BETTING_URL + "/bet";
      bettingserve
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          var message = res.data.data;
          vm.bet_id = res.data.bet_id;
          var shareLink = `https://dev.chopbet.ci/share/${vm.bet_id}`;
          vm.setSuccess(`${message} Share your bet: ${shareLink}`);
          vm.hideBetslip();
          vm.clearBetSlip();
          vm.trackPlaceBetSuccessfulButtonClick(event);
          // document.getElementById('share-bet-link').textContent = `https://dev.chopbet.ci/share/${this.bet_id}`;

          // this.$nextTick(() => {
          //   var collapseShareSlip = document.getElementById('collapseShareSlip');
          //   if (collapseShareSlip) {
          //     collapseShareSlip.classList.add('show');
          //   }
          // });
          vm.removeObject("booking_code");
          vm.removeObject("utm_source");
          this.showSuccessModal = true;
          setTimeout(() => {
            this.showSuccessModal = false;
          }, 2000);
        })
        .catch((err) => {
          vm.hideBetslip();
          vm.loading = "";
          vm.trackPlaceBetUnsuccessfulButtonClick(event);

          if (err.response) {
            var message = err.response.data.error_message || "Unknown error";

            if (parseInt(err.response.status) === 428) {
              let message = err.response.data.error_message || "Unknown error";
              this.setError(message);
              return;
            }

            if (parseInt(err.response.status) === 401) {
              this.setError(`${this.$t("sessionExpired")}`);
              vm.logout();
              return;
            } else if (parseInt(err.response.status) === 422) {
              this.setError(err.response.data.error_message);
            } else {
              this.setError(message);
            }

            console.log("Response Error:", JSON.stringify(err.response));
          } else if (err.request) {
            this.setError("Please check your network");
            console.log("Request Error:", JSON.stringify(err.request));
          } else {
            console.log("Error============:", JSON.stringify(err));
          }
        });
    },
    oddStatus: function () {
      var picks = this.betslip.picks;
      if (picks === undefined || picks.length === 0) {
        return;
      }
      var odds = [];
      this.jQuery.each(picks, function (k, v) {
        odds.push({
          odd_id: parseInt(v.odd_id),
          producer_id: parseInt(v.producer_id),
        });
      });
      var data = {
        odds: odds,
      };
      if (odds.length === 0) {
        return;
      }
      var vm = this;
      var path = process.env.VUE_APP_URL_ODD_STATUS;
      fix
        .post(path, JSON.stringify(data))
        .then((res) => {
          var bets = res.data.message;

          vm.jQuery.each(bets, function (k, v) {
            var oddID = v.id;
            var odds = v.odds;
            var previous_odds = v.previous_odds;
            var status = v.status;
            var active = v.active;
            var producer_status = v.producer_status;
            vm.jQuery.each(picks, function (k1, v1) {
              if (parseInt(v1.odd_id) === parseInt(oddID)) {
                var oddChangeText = "";
                odds = vm.formatOdds(odds);
                if (odds !== vm.formatOdds(v1.odd)) {
                  var direction = odds > v1.odd ? 1 : 0;
                  var directionTxt =
                    parseInt(direction) === 1
                      ? "odds has gone up "
                      : "odds has gone down ";
                  oddChangeText =
                    directionTxt + " from " + v1.odd + " to " + odds;
                }
                v1.status = status;
                v1.active = active;
                v1.odds = odds;
                v1.odd = odds;
                v1.previous_odds = previous_odds;
                v1.producer_status = producer_status;
                v1.odds_change_text = oddChangeText;
                picks[k1] = v1;
              }
            });
          });
          var pk = [];
          vm.jQuery.each(picks, function (k1, v1) {
            pk.push(v1);
          });
          vm.saveObject("bslip", pk);
          vm.autoRefreshUI(vm.$vnode.tag);
        })
        .catch((err) => {
          if (err.response) {
            this.setError(err.response.data.message);
          }
        });
    },
    getOddStatus: function (odd) {
      if (odd.active === 0 || odd.producer_status === 0) {
        return '<div class="deactivated-odds">Outcome suspended</div>';
      }
      if (parseInt(odd.status) === 0 || parseInt(odd.status) === -1) {
        return '<div class="deactivated-odds">Outcome Deactivated</div>';
      }
      if (odd.odds_change_text && odd.odds_change_text.length > 0) {
        return (
          '<div class="odds-change" style="">' + odd.odds_change_text + "</div>"
        );
      }
    },
    initSharebetModal: function () {
      var modal = document.getElementById("sharebet-modal");
      var btn = document.getElementById("open-sharebet");
      btn.onclick = function () {};
      window.onclick = function (event) {
        if (event.target == modal) {
          modal.style.display = "none";
        }
      };
      document.addEventListener("click", (e) => {
        if (e.target == document.querySelector("#sharebet-modal")) {
          modal.style.display = "none";
        }
      });
    },
    initBetslipModal: function () {
      var vm = this;
      var modal = document.getElementById("betslip-modal");
      var btn = document.getElementById("betslip-init");
      var span = document.getElementById("faso-close");
      btn.onclick = function () {
        modal.style.display = "block";
        vm.previous_odds = vm.betslip.odds;
        vm.oddStatus();
        vm.tax();
      };
      span.onclick = function () {
        modal.style.display = "none";
      };
      window.onclick = function (event) {
        if (event.target == modal) {
          modal.style.display = "none";
        }
      };
      document.addEventListener("click", (e) => {
        if (e.target === document.querySelector("#betslip-modal")) {
          modal.style.display = "none";
        }
      });
      document.addEventListener("click", (e) => {
        if (e.target === document.querySelector("#betslip-modal")) {
          modal.style.display = "none";
        }
      });
    },
    incrementStake: function () {
      console.log(this.stake);
      if (!this.can_get_bonus) {
        this.stake = this.stake + 10;
      }
    },
    decrementStake: function () {
      if (this.stake - 10 > 9 && !this.can_get_bonus) {
        this.stake = this.stake - 10;
      }
    },
    showBetslip: function () {
      if (document.getElementById("betslip-init") !== null) {
        document.getElementById("betslip-init").click();
        this.$store.dispatch("setPlaceBet", 0);
      }
    },
    hideBetslip: function () {
      document.getElementById("faso-close").click();
    },
    shareBet: function () {
      var p = this.getProfile();
      var betslipData = this.betslip;
      if (betslipData.total === 0) {
        this.setError("Please Select atleast one outcome to continue");
        return;
      }

      var bets = [];
      this.jQuery.each(betslipData.picks, function (k, v) {
        bets.push({
          market_id: v.market_id,
          match_id: v.match_id,
          outcome_id: v.outcome_id,
          specifier: v.specifier,
        });
      });
      var data = {
        profile_id: p.id,
        bets: bets,
        bet_type: 1,
      };
      this.loading = "loading";
      var vm = this;
      var path = process.env.VUE_APP_URL_SHARE;
      axios
        .post(path, JSON.stringify(data))
        .then((res) => {
          vm.loading = "";
          vm.code = res.data.message.code;
          vm.msg = res.data.error_message;
          vm.share_odds = betslipData.odds;
          vm.shareBetMessage();
          vm.copyText = "Copy";
          vm.copyBookingCode = "Click to Copy";
          document.getElementById("open-sharebet").click();
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            this.setError(err.response.data.message);
          } else if (err.request) {
            //
          } else {
            //
          }
        });
    },
    copyShareBetLink: function () {
      var link = "https://dev.chopbet.ci/share/" + this.bet_id;
      navigator.clipboard
        .writeText(link)
        .then(() => {
          this.copyText = "Copied";
          // this.showSuccessModal = false;
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    },
    clearAndCloseBetSlip: function () {
      this.hideBetslip();
      this.clearBetSlip();
    },
    copyCode: function () {
      var link = "book#" + this.code + "#stakeAmount";
      this.copyToClipboard(link);
      this.copyBookingCode = "Booking Code Copied";
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    bal: function () {
      return this.formatCurrency(this.$store.state.balance);
    },
    iconSize: function () {
      return 28;
    },
    profile: function () {
      return this.myProfile;
    },
    liveGames: function () {
      return this.$store.state.live_match_counter;
    },
    homePageIcon: function () {
      if (this.page === "home") {
        return this.getURL("/assets/images/home_icon_selected.svg");
      }
      return this.getURL("/assets/images/home_icon.svg");
    },
    homePageFontColor: function () {
      if (this.page === "home") {
        return "yellow-txt";
      }
      return "";
    },
    livePageFontColor: function () {
      if (this.page === "live") {
        return "yellow-txt";
      }
      return "";
    },
    historyPageFontColor: function () {
      if (this.page === "history") {
        return "yellow-txt";
      }
      return "";
    },
    betslip_count: function () {
      if (!this.betslip.total || this.betslip.total == "") {
        return 0;
      }
      return parseInt(this.betslip.total);
    },
    activeBets: function () {
      var p = this.getProfile();
      if (!p) {
        return 0;
      }
      return p.b;
    },
    odds: function () {
      return this.betslip.odds;
    },
    payout: function () {
      return this.betslip.payout;
    },
    betslip: function () {
      return this.$store.state.betslip;
    },
    has_suspended_picks: function () {
      return this.$store.state.has_suspended_picks;
    },
    isLoggedIn: function () {
      var p = this.getProfile();
      if (!p) {
        return false;
      }
      return true;
    },
    modalClass: function () {
      if (parseInt(this.betslip_count) === 0) {
        return "faso-modal-booking-code";
      } else {
        return "faso-modal";
      }
    },
    modalContentClass: function () {
      if (parseInt(this.betslip_count) === 0) {
        return "faso-modal-content-booking-code";
      } else {
        return "faso-modal-content";
      }
    },
  },
  filters: {
    currency: function (amount) {
      if (amount == null) {
        amount = 0;
      }
      return amount.toLocaleString("en-US", {
        style: "currency",
        currency: "CFA",
      });
    },
    formatOdds: function (x) {
      if (x === undefined) {
        return 1;
      }
      return parseFloat(x).toFixed(2);
    },
  },
  watch: {
    stake: function (newValue) {
      if (parseInt(newValue) < 1) {
        this.stake = 1;
        newValue = 1;
      }
      this.setValue("stake", newValue);
      this.autoRefreshUI(this.$vnode.tag);
    },
    betslip_count: function (newValue) {
      if (parseInt(newValue) === 0) {
        this.hideBetslip();
      }
    },
  },
  components: {
    // BetslipModalToggle,
    BottomNavigationMenu,
  },
};
</script>

<style scoped>
.title {
  flex-grow: 1;
}

.count {
  background: var(--red-400);
  color: var(--darkBackground);
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-size: 1.1rem;
  font-weight: bold;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, 50px);
  height: 100vh;
  position: absolute;
}

.modal-dialog {
  height: 100vh;
  /* Full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  margin: 0 !important;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-content {
  height: 100%;
  /* Ensures the content fills the modal dialog */
  background-color: var(--gray-white);
  padding: 0 !important;
  border: none !important;
  border-radius: 0 !important;
  display: flex;
  flex-direction: column;
}

.betslip-modal .modal-dialog {
  box-shadow: 0 -20px 60px rgba(0, 0, 0, 0.2);
}

.app {
  /* display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll; */
}

.counter {
  font-size: 10px;
  position: relative;
  top: 5px !important;
  margin-left: 17px;
  color: #0ca678;
}
</style>
