import axios from "axios";

const walletserve = axios.create({
  baseURL: process.env.VUE_APP_BASE_WALLET_URL,
});

walletserve.defaults.headers.post["Content-Type"] = "application/json";
walletserve.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

walletserve.defaults.method = "post";

walletserve.interceptors.request.use(
  (config) => {
    const lang = localStorage.getItem("locale") || "en";
    config.headers["lang"] = lang;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default walletserve;
