import walletserve from "@/services/walletserve";

export const fetchTransactions = async ({
  page = 1,
  per_page = 10,
  start = "",
  end = "",
  sort = "created|desc",
  transaction_type = 0,
  reference_id = 0,
  apiKey,
}) => {
  const queryParams = {
    page,
    per_page,
    start,
    end,
    sort,
    transaction_type,
    reference_id,
  };
  const path = "/transactions";

  try {
    const response = await walletserve.get(path, {
      headers: {
        "api-key": apiKey,
      },
      params: queryParams,
    });
    return response.data;
  } catch (error) {
    let errorMessage = "An error occurred";

    if (error.response) {
      const status = error.response.status;
      errorMessage = error.response.data.message;

      if ([401, 400, 428].includes(status)) {
        errorMessage = "Your session on this device has expired";
        return;
      }
    }

    throw new Error(errorMessage);
  }
};
