<template>
  <ul class="filter_nav">
    <li
      @click="setActive(index)"
      :class="active == index ? 'active_tabs' : 'tabs'"
      :key="index"
      v-for="(game, index) in tabs"
    >
      {{ $t(game.label) }}
    </li>
  </ul>
</template>

<script>
export default {
  name: "PlayJackpotFilter",
  data() {
    return {
      active: 0,
      // filters: ["games", "results", "rules"],
    };
  },
  props: {
    tabs: {
      required: false,
      default: [{ label: "games" }, { label: "results" }, { label: "rules" }],
    },
  },
  methods: {
    setActive(index) {
      this.active = index;
      // Emit the active value to the parent
      this.$emit("updateActive", this.active);
    },
  },
};
</script>

<style scoped>
.filter_nav {
  /* outline: 4px solid red; */
  /* margin-top: 1.5rem; */
  height: 36px;
  border: 1px solid var(--fixtures-border);
  padding: 0 20px;
  list-style: none;
  display: flex;
  justify-content: space-between;
  /* grid-template-columns: repeat(3, 1fr); */
  border-left: none;
  border-right: none;
}
.filter_nav li {
  flex: 1;
  border-bottom: 1px solid var(--leaderboard-border);
}
.filter_nav .active_tabs {
  border-bottom: 1px solid #a31212;
}
.tabs {
  font-size: 12px;
  font-weight: 500;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: #8c8787; */
  color: var(--sub-text-color);
  cursor: pointer;
}
.active_tabs {
  font-size: 12px;
  font-weight: 500;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #a31212;
  /* color: #0e0d0d; */
  color: var(--text-color);
}
</style>
