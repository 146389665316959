import { render, staticRenderFns } from "./FreeBet.vue?vue&type=template&id=df30c968&scoped=true"
import script from "./FreeBet.vue?vue&type=script&lang=js"
export * from "./FreeBet.vue?vue&type=script&lang=js"
import style0 from "./index.css?vue&type=style&index=0&id=df30c968&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df30c968",
  null
  
)

export default component.exports