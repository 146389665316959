<template>
  <div class="stat_details">
    <span>{{ label }}</span>
    <p>{{ digit }}</p>
  </div>
</template>

<script>
export default {
  name: "StatDetail",
  props: {
    label: {
      type: String,
    },
    digit: {
      type: String,
    },
  },
};
</script>

<style src="./index.css" scoped></style>
