<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99999 18.3333C14.5833 18.3333 18.3333 14.5833 18.3333 9.99996C18.3333 5.41663 14.5833 1.66663 9.99999 1.66663C5.41666 1.66663 1.66666 5.41663 1.66666 9.99996C1.66666 14.5833 5.41666 18.3333 9.99999 18.3333Z"
      stroke="var(--text-color)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.64166 12.3583L12.3583 7.64166"
      stroke="var(--text-color)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.3583 12.3583L7.64166 7.64166"
      stroke="var(--text-color)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "CloseIcon",
  props: {
    width: {
      type: String,
      default: "20px",
    },
    height: {
      type: String,
      default: "20px",
    },
  },
};
</script>
