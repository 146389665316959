import { render, staticRenderFns } from "./BackComponent.vue?vue&type=template&id=70e4c9fe&scoped=true"
import script from "./BackComponent.vue?vue&type=script&lang=js"
export * from "./BackComponent.vue?vue&type=script&lang=js"
import style0 from "./BackComponent.vue?vue&type=style&index=0&id=70e4c9fe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70e4c9fe",
  null
  
)

export default component.exports