<template>
  <div class="claim_fixture">
    <div class="fixture">
      <p>
        {{ currentFixture.tournament }}
      </p>
      <span> {{ formatMatchDate(currentFixture.date) }}</span>
    </div>

    <div class="team_odd">
      <div class="team">
        <p>{{ home_team }}</p>
        <p>{{ away_team }}</p>
      </div>
      <div class="odds">
        <div v-for="(o, i) in currentFixture.outcomes" v-bind:key="i">
          <FreeBetOdd
            v-bind:match_id="currentFixture.match_id"
            v-bind:outcome_name="o.outcome_name"
            v-bind:home_team="home_team"
            v-bind:away_team="away_team"
            v-bind:market_id="o.market_id"
            v-bind:outcome_id="o.outcome_id"
            v-bind:specifier="o.specifier"
            v-bind:active="o.active"
            v-bind:status="true"
            v-bind:odds="o.odds"
            v-bind:producer_status="true"
            v-bind:alias="o.alias"
            v-bind:unique="getUnique(i)"
            v-bind:pick="o"
            :currentFixture="currentFixture"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style src="./index.css" scoped></style>

<script>
import FreeBetOdd from "./FreeBetOdd.vue";

export default {
  components: {
    FreeBetOdd,
  },
  name: "FreeBetGames",
  data: function () {
    return {
      color: "",
      seconds: 0,
      to_start: "",
      formattedFixture: "",
      upcoming: "",
      isLive: "",
      odds: "",
      status: "",
      active: "",
      currentFixture: [],
    };
  },
  props: {
    fixture: {
      required: true,
    },
    live: {
      required: false,
      default: false,
    },
    current_row: {
      required: true,
      default: 0,
    },
  },
  computed: {
    home_team: function () {
      return this.getHomeCompetitorName(this.currentFixture.name);
    },
    away_team: function () {
      return this.getAwayCompetitorName(this.currentFixture.name);
    },
    sportIcon: function () {
      return this.getSportIcon(this.currentFixture.sport_id);
    },
  },
  methods: {
    getUnique: function (column) {
      return "r" + this.current_row + "c" + column;
    },
    getKey: function (index) {
      var prefix = "odds-" + index;
      return Math.random()
        .toString(10)
        .replace("0.", "odd-id-" + prefix + "-");
    },
    getHomeCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }

      return matchName.split("vs.")[0];
    },
    getAwayCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }
      return matchName.split("vs.")[1];
    },
  },
  mounted: function () {
    // var vm = this;
    if (Array.isArray(this.fixture)) {
      this.currentFixture = this.fixture[0];
    } else {
      this.currentFixture = this.fixture;
    }
  },
};
</script>
