<template>
  <span>
    <svg
      v-if="isDarkMode"
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="32" height="32" rx="16" fill="#FDE2E2" />
      <path
        d="M18.694 14.2089H13.2574V18.826H18.714V17.1371H20.6727L20.6827 19.3257C20.7427 19.8121 20.4995 20.1519 19.9532 20.3451C19.72 20.4317 19.4402 20.4817 19.1137 20.495C18.2609 20.5017 16.0456 20.5017 12.4679 20.495C11.7483 20.495 11.3619 20.1086 11.3086 19.3357C11.3219 18.363 11.3219 16.4009 11.3086 13.4494C11.3086 12.8631 11.8149 12.5466 12.8276 12.5H19.1237C20.0764 12.5 20.5895 12.8165 20.6627 13.4494V15.658H18.694V14.2089Z"
        fill="#DD4646"
      />
      <rect x="20.5" y="19.5" width="13" height="13" rx="6.5" fill="#BC6EE0" />
      <rect
        x="20.5"
        y="19.5"
        width="13"
        height="13"
        rx="6.5"
        stroke="#080808"
      />
      <path
        d="M27.8575 28.004L24.9961 28.004L24.9961 25.1426"
        stroke="#F7F7F7"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.0039 23.998L25.037 27.9649"
        stroke="#F7F7F7"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <svg
      v-else
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="32" height="32" rx="16" fill="#FCF2F2" />
      <path
        d="M18.6939 14.2089H13.2573V18.826H18.7138V17.1371H20.6726L20.6826 19.3257C20.7426 19.8121 20.4994 20.1519 19.9531 20.3451C19.7199 20.4317 19.4401 20.4817 19.1136 20.495C18.2608 20.5017 16.0455 20.5017 12.4677 20.495C11.7482 20.495 11.3618 20.1086 11.3085 19.3357C11.3218 18.363 11.3218 16.4009 11.3085 13.4494C11.3085 12.8631 11.8148 12.5466 12.8275 12.5H19.1236C20.0763 12.5 20.5893 12.8165 20.6626 13.4494V15.658H18.6939V14.2089Z"
        fill="#CC1717"
      />
      <rect x="20.5" y="19.5" width="13" height="13" rx="6.5" fill="#DD4646" />
      <rect x="20.5" y="19.5" width="13" height="13" rx="6.5" stroke="white" />
      <path
        d="M26.1421 23.996L29.0035 23.996L29.0035 26.8574"
        stroke="white"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.9965 28.002L28.9634 24.0351"
        stroke="white"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>

<script>
export default {
  data: function () {
    return {
      isDarkMode: false,
    };
  },
  mounted() {
    this.isDarkMode =
      document.documentElement.getAttribute("data-theme") === "dark";
    const observer = new MutationObserver(() => {
      this.isDarkMode =
        document.documentElement.getAttribute("data-theme") === "dark";
    });
    observer.observe(document.documentElement, { attributes: true });
  },
};
</script>
