import { render, staticRenderFns } from "./Payments.vue?vue&type=template&id=b7a825aa&scoped=true"
import script from "./Payments.vue?vue&type=script&lang=js"
export * from "./Payments.vue?vue&type=script&lang=js"
import style0 from "./payments.css?vue&type=style&index=0&id=b7a825aa&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7a825aa",
  null
  
)

export default component.exports