import axios from "axios";

const shacksevo = axios.create({
  baseURL: process.env.VUE_APP_SHACKSEVO_BASE_URL,
});

shacksevo.defaults.headers.post["Content-Type"] = "application/json";
shacksevo.defaults.method = "post";

shacksevo.interceptors.request.use(
  (config) => {
    const lang = localStorage.getItem("locale") || "en";
    config.headers["lang"] = lang;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default shacksevo;
