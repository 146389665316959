import bettingserve from "../services/bettingserve";
import fixtures from "../services/fixtures";

export const fetchFixtures = async ({ commit, state }, payloadData) => {
  try {
    // console.log(state, "ss");
    let path = `/highlights/${state.fixture.sport_id}`;
    let payload = {
      page: 1,
      per_page: 10,
      category_id: 0,
      order_type: "priority",
      ...payloadData,
    };
    // console.log(payloadData, "payload");
    commit("setLoadingFixtures", true);
    const res = await fixtures.get(path, { params: payload });
    commit("setLoadingFixtures", false);
    commit("setSportFixtures", res?.data?.data);
    return res?.data?.data;
  } catch (error) {
    // console.error("Error fetching data:", error);
    commit("setError", error);
  } finally {
    commit("setLoadingFixtures", false);
  }
};

export const loadBetSlip = async ({ booking_code, lang, apiKey }) => {
  var path = process.env.VUE_APP_BASE_BETTING_URL + `/bookings/${booking_code}`;
  try {
    const response = await bettingserve.get(path, {
      headers: {
        "api-key": apiKey,
      },
      params: { lang: lang },
    });
    return response.data;
  } catch (error) {
    let errorMessage = "Oops! An error occurred";
    if (error.response) {
      const status = error.response.status;
      if ([401, 400, 428].includes(status)) {
        errorMessage = "Your session has gone poof! Please log back in.";
        return;
      } else {
        errorMessage = error.response.data.message || errorMessage;
      }
    }
    if (error.response.data.error_message) {
      errorMessage = error.response.data.error_message;
    }
    return { error: errorMessage };
  }
};

export const fetchBets = async ({ page = 1, per_page = 10, apiKey }) => {
  const queryParams = {
    page,
    per_page,
  };
  // const path = "/bet";
  var path = process.env.VUE_APP_BASE_BETTING_URL + "/bet";

  try {
    const response = await bettingserve.get(path, {
      headers: {
        "api-key": apiKey,
      },
      params: queryParams,
    });

    if (response.data.length < 1) {
      // console.log("No bets found, maybe try betting more? ");
      return [];
    }

    // console.log("Bets fetched successfully:", response.data);
    return response.data;
  } catch (error) {
    // console.error("Something went wrong fetching those bets:", error);

    let errorMessage = "Oops! An error occurred";

    if (error.response) {
      const status = error.response.status;

      // Expired session or other status-based hijinks
      if ([401, 400, 428].includes(status)) {
        errorMessage = "Your session has gone poof! Please log back in.";

        return;
      } else {
        errorMessage = error.response.data.message || errorMessage;
      }
    }

    throw new Error(errorMessage);
  }
};
