import prag from "@/services/prag";
import stp from "@/services/stp";
import elbet from "@/services/elbet";
import smart from "@/services/smart";
import jetsafi from "@/services/jetsafi";
import spribe from "@/services/spribe";
import booming from "@/services/booming";
import shacksevo from "@/services/shacksevo";

const providerServiceMap = {
  [process.env.VUE_APP_PROVIDER_ID_PRAG]: prag,
  [process.env.VUE_APP_PROVIDER_ID_STP]: stp,
  [process.env.VUE_APP_PROVIDER_ID_ELBET]: elbet,
  [process.env.VUE_APP_PROVIDER_ID_SMART]: smart,
  [process.env.VUE_APP_PROVIDER_ID_JETSAFI]: jetsafi,
  [process.env.VUE_APP_PROVIDER_ID_SPRIBE]: spribe,
  [process.env.VUE_APP_PROVIDER_ID_BOOMING]: booming,
  [process.env.VUE_APP_PROVIDER_ID_SHACKSEVO]: shacksevo,
};

export default providerServiceMap;