<template>
  <div class="page_container">
    <div v-if="showSuccess" class="success page_container">
      <span class="rotate">
        <SuccessIcon />
      </span>
      <h3>{{ $t("depositRequest") }}</h3>
      <p>{{ $t("yourDepositRequestSent") }}</p>
      <router-link to="/">
        <ChopbetButton variant="outline">{{ $t("backToGame") }} </ChopbetButton>
      </router-link>
    </div>
    <div v-else class="success page_container">
      <span class="red">x</span>
      <h3>{{ $t("transactionFailed") }}</h3>
      <p>{{ $t("yourTransactionFailed") }}</p>
      <router-link to="/deposit">
        <ChopbetButton variant="outline">{{ $t("tryAgain") }}</ChopbetButton>
      </router-link>
    </div>
  </div>
</template>

<script>
import ChopbetButton from "../../../components/ui/ChopbetButton.vue";
import SuccessIcon from "../../../components/icons/SuccessIcon.vue";

export default {
  name: "Status",
  components: {
    SuccessIcon,
    ChopbetButton,
  },
  mounted() {
    const params = this.$route.params.status;
    if (params === "success") {
      this.showSuccess = true;
    } else {
      this.showSuccess = false;
    }
    setTimeout(() => {
      this.$router.push("/");
    }, 5000);
  },
  watch: {
    showSuccess(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.$router.push("/");
        }, 5000);
      }
    },
  },
  data() {
    return {
      showSuccess: true,
    };
  },
};
</script>

<style scoped>
.success {
  font-family: "Satoshi";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.12px;
  display: flex;
  padding-top: 10vh;
  flex-direction: column;
  text-align: center;
}

.success img {
  margin-bottom: 3rem;
}
.success svg {
  margin: 0 auto;
  margin-bottom: 16px;
}

.red {
  background: red;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  margin-bottom: 20px;
}
.success p {
  color: var(--text-color);
  width: 80%;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.success h3 {
  color: var(--text-color);
  text-align: center;
  font-family: "Satoshi";
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.14px;
}

.rotate svg {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
