<template>
  <div class="wrapper">
    <div>
      <BackComponent :title="$t('Referrals')" goto="/" />
    </div>
    <div class="select-sport">
      <button
        :class="{ active: activeCategory === 'summary' }"
        @click="setCategory('summary')"
      >
        {{ $t("Summary") }}
      </button>
      <button
        :class="{ active: activeCategory === 'payments' }"
        @click="setCategory('payments')"
      >
        {{ $t("Payments") }}
      </button>
      <button
        :class="{ active: activeCategory === 'information' }"
        @click="setCategory('information')"
      >
        {{ $t("Information") }}
      </button>
    </div>
    <div v-if="activeCategory === 'summary'" class="referral_summary">
      <div class="summary_filter">
        <div class="summary_date">
          <input type="date" />
        </div>
        <div class="players_info">
          <StatDetail label="Total signups" digit="2" />
          <StatDetail label="Total Players" digit="2" />
          <StatDetail label="Total Wager" digit="2" />
          <StatDetail label="Total Commission" digit="2" />
        </div>
      </div>
      <div class="commission">
        <div class="commission_heading">
          <p>Commission</p>
        </div>
        <div class="agent_level">
          <div class="agent_icon">
            <div class="info">
              <InfoCircle />
            </div>
            <AgentLevel />
            <p>Agent level</p>
          </div>
          <div class="agent_cta">
            <div class="commission_track">
              <StatDetail label="Total paid" digit="0.00" />
              <StatDetail label="Affiliate balance" digit="0.00" />
            </div>
            <div class="cta">
              <ChopbetButton variant="outline" :size="'small'"
                >Transfer to wallet</ChopbetButton
              >
              <p>Transfer will be available once you reach KES 100</p>
            </div>
          </div>
        </div>
      </div>
      <div class="share_modal">
        <div class="socials_wrap">
          <div class="share_heading">
            <p>Share by email or social media</p>
            <p>
              {{ $t("shareOnAnySocial") }}
            </p>
          </div>
          <div class="referral_link">
            <div class="referral_code">
              <p>Referral link</p>
              <div>
                <p>Gamewinner.com/ke/hhttxxx</p>
                <CopyIcon />
              </div>
            </div>
            <div class="referral_code">
              <p>Referral link</p>
              <div>
                <p>Gamewinner.com/ke/hhttxxx</p>
                <CopyIcon />
              </div>
            </div>
          </div>
          <div class="socials">
            <button>
              <LinkIcon />
            </button>
            <button>
              <Facebook />
            </button>
            <button>
              <Whatsapp />
            </button>
            <button>
              <Telegram />
            </button>
          </div>
        </div>
        <div class="qr_code_wrapper">
          <div class="qr_code" @click="openSlideUp()">
            <QrCode />
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="activeCategory === 'payments'" class="referral_summary">
      <Payments />
    </div>
    <div v-else class="referral_summary">
      <Informations />
    </div>
    <ChopbetSlideUp :isOpen="isSlideUpOpen" @closeSlideup="closeSlideUp">
      <div class="scan_modal">
        <div class="close">
          <span @click="closeSlideUp()"><CancelIcon /></span>
        </div>
        <div class="scan_code">
          <div class="scan_description">
            <p>Scan QR Code</p>
            <p class="des">
              Scan this QR code with your smartphone to get started on Chopbet
            </p>
          </div>
          <div class="code">
            <QrCodeLarge />
          </div>
        </div>
      </div>
    </ChopbetSlideUp>
  </div>
</template>

<script>
import BackComponent from "../../components/ui/BackComponent.vue";
import AgentLevel from "../../components/icons/AgentLevel.vue";
import InfoCircle from "../../components/icons/InfoCircle.vue";
import CopyIcon from "../../components/icons/CopyIcon.vue";
import QrCodeLarge from "../../components/icons/QrCodeLarge.vue";
import StatDetail from "./StatDetail.vue";
import Payments from "./payments/Payments.vue";
import ChopbetButton from "../../components/ui/ChopbetButton.vue";
import Facebook from "../../components/icons/Facebook.vue";
import LinkIcon from "../../components/icons/LinkIcon.vue";
import Whatsapp from "../../components/icons/Whatsapp.vue";
import Telegram from "../../components/icons/Telegram.vue";
import CancelIcon from "../../components/icons/CancelIcon.vue";
import QrCode from "../../components/icons/QrCode.vue";
import ChopbetSlideUp from "../../components/ui/ChopbetSlideUp.vue";
import Informations from "./informations/Informations.vue";

export default {
  name: "MyReferral",
  components: {
    ChopbetSlideUp,
    Informations,
    BackComponent,
    AgentLevel,
    CancelIcon,
    InfoCircle,
    CopyIcon,
    StatDetail,
    Payments,
    ChopbetButton,
    Facebook,
    LinkIcon,
    Whatsapp,
    Telegram,
    QrCodeLarge,
    QrCode,
  },
  data() {
    return {
      isSlideUpOpen: false,
      activeCategory: "summary",
      currency: process.env.VUE_APP_CURRENCY,
      lang: this.$i18n.locale,
    };
  },
  methods: {
    setCategory(category) {
      this.activeCategory = category;
    },
    closeSlideUp() {
      console.log("first");
      this.isSlideUpOpen = false;
    },
    openSlideUp() {
      console.log("first");
      this.isSlideUpOpen = true;
    },
  },
};
</script>

<style src="./index.css" scoped></style>
