<template>
  <div class="faq">
    <PlayJackpotFilter :tabs="tabs" @updateActive="handleActiveChange" />
    <div v-if="activeTab === 0">
      <Rule />
    </div>
    <div v-if="activeTab === 1">
      <Faq />
    </div>
  </div>
</template>

<script>
import PlayJackpotFilter from "../../play-jackpot/components/PlayJackpotFilter.vue";
import Faq from "./faq/Faq.vue";
import Rule from "./rules/Rule.vue";

export default {
  name: "Informations",
  data: function () {
    return {
      activeTab: 0,
      tabs: [
        {
          label: "Rules",
          name: "rules",
        },
        {
          label: "FAQ",
          name: "faq",
        },
      ],
    };
  },
  components: {
    Faq,
    PlayJackpotFilter,
    Rule,
  },
  methods: {
    handleActiveChange(activeValue) {
      this.activeTab = activeValue;
    },
  },
};
</script>

<style src="./payments.css" scoped></style>
