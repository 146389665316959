<template>
  <div class="wrapper">
    <div v-if="isSuccess" class="page_container">
      <div class="success_wrap">
        <div>
          <SuccessCard
            :title="$t('congratulations')"
            :description="$t('yourFreebetPlacedSuccessfully')"
          />
          <router-link :to="`/deposit`">
            <ChopbetButton variant="primary">
              {{ $t("depositNow") }}
            </ChopbetButton>
          </router-link>
        </div>
      </div>
    </div>
    <section v-else class="free_bet">
      <div class="account_created">
        <h4 class="text-dark text-center activate_your_account">
          {{ $t("accountCreated") }}
        </h4>
        <p class="verify_phone">
          {{ $t("verifyPhone") }}
        </p>
      </div>

      <div class="claim_wrapper">
        <div class="claim_heading">
          <div class="claim_img">
            <ClaimIcon />
          </div>
          <div class="claim_text">
            <p>{{ $t("claimFreeBet") }}</p>
            <p>{{ $t("claimFreeBetDescription") }}</p>
          </div>
        </div>

        <div v-if="loading">
          <FreeBetSkeleton :count="1" />
        </div>

        <div class="claim_fixture" v-else>
          <FreeBetGames
            v-for="(match, index) in fixture"
            :key="getLeagueKey(index)"
            :current_row="index"
            :fixture="match"
          ></FreeBetGames>

          <div class="possible_win">
            <p>
              <span>{{ $t("possibleWin") }}: </span>
            </p>
            <div class="skeleton-item" v-if="balanceLoading"></div>
            <span class="balance" v-else>
              <span class="currency">{{ fiatCurrency }} </span>
              {{ possibleWin }}
            </span>
          </div>
          <div class="possible_win">
            <p>
              <span>{{ $t("freebetStake") }}: </span>
            </p>
            <div class="skeleton-item" v-if="balanceLoading"></div>
            <span class="balance" v-else>
              <span class="currency">{{ fiatCurrency }} </span>
              {{ freebetStake }}
            </span>
          </div>
          <div class="possible_win">
            <p>
              <span>{{ $t("finalPayout") }}: </span>
              <img src="../components/InfoCircle.svg" />
            </p>
            <div class="skeleton-item" v-if="balanceLoading"></div>
            <span class="balance" v-else>
              <span class="currency">{{ fiatCurrency }} </span>
              {{ finalPayout }}
            </span>
          </div>

          <ChopbetButton :loading="spinner" size="small" @click="placeFreeBets">
            <span style="text-transform: uppercase">{{
              $t("confirmFreeBet")
            }}</span>
          </ChopbetButton>
        </div>
      </div>

      <!-- <div class="bonus">
        <div
          v-for="(bonus, index) in bonuses"
          :key="index"
          class="bonus_content"
          @click="navigateTo(bonus.link)"
        >
          <div class="text">
            <p>{{ $t(bonus.title) }}</p>
            <span>{{ $t(bonus.description) }}</span>
          </div>
          <span>
            <ArrowRight v-if="bonus.showArrow" />
          </span>
        </div>
      </div> -->
    </section>
  </div>
</template>

<script>
import ClaimIcon from "../components/ClaimIcon.vue";
// import ArrowRight from "@/components/ui/ArrowRight.vue";
import bettingserve from "../../../services/bettingserve";
import ChopbetButton from "../../../components/ui/ChopbetButton.vue";
import FreeBetGames from "./FreeBetGames.vue";
import FreeBetSkeleton from "./components/FreeBetSkeleton.vue";
import SuccessCard from "../../../components/ui/SuccessCard.vue";

export default {
  name: "free-bet",
  components: {
    FreeBetSkeleton,
    FreeBetGames,
    // ArrowRight,
    ClaimIcon,
    ChopbetButton,
    SuccessCard,
  },
  data: function () {
    return {
      fiatCurrency: process.env.VUE_APP_CURRENCY,
      loading: true,
      balanceLoading: true,
      color: "",
      seconds: 0,
      to_start: "",
      formattedFixture: "",
      upcoming: "",
      isLive: "",
      odds: "",
      status: "",
      active: "",
      spinner: false,
      fixture: [],
      balance: "",
      fixtureBet: {},
      isSuccess: false,
      bonuses: [
        {
          title: "bonus1Title",
          description: "bonus1Description",
          link: "/deposit",
          showArrow: true,
        },
        {
          title: "bonus2Title",
          description: "bonus2Description",
          link: "/",
          showArrow: true,
        },
        // {
        //   title: "bonus3Title",
        //   description: "bonus3Description",
        //   link: "/",
        //   showArrow: true,
        // },
      ],
    };
  },
  methods: {
    navigateTo(link) {
      this.$router.push(link);
    },
    handleApply() {
      console.log("Hello");
    },
    getLeagueKey: function (index) {
      return Math.random()
        .toString(10)
        .replace("0.", "competition-id-" + index + "-");
    },
    async getFreeBalance() {
      try {
        const path = `${process.env.VUE_APP_BASE_FREEBET_URL}/balance`;
        const response = await bettingserve.get(path, {
          headers: {
            "api-key": this.getAuth(),
          },
        });
        this.balance = response.data.balance;
      } catch (error) {
        if (error.response) {
          console.log("Error response:", error.response);
          if ([401, 400, 428].includes(error.response.status)) {
            this.setError(`${this.$t("sessionExpired")}`);
          } else {
            this.setError(error.response.data.message);
          }
        } else {
          // You can set a general error message here if needed
          // this.setError("An error occurred while fetching data. Please try again later.");
        }
      } finally {
        this.balanceLoading = false;
        this.loading = false;
      }
    },

    async getFreeBets() {
      try {
        this.balanceLoading = true;
        const m = this.getProfile();
        const p = m.a;
        if (!p) {
          this.setError(this.$t("pleaseLoginProceed"));
          this.$router.push({ name: "login", params: {} });
          return;
        }

        // const date = new Date().toISOString().substr(0, 10);?start=${date}
        const path = `${process.env.VUE_APP_BASE_FREEBET_URL}/freebet/fixture`;
        const response = await bettingserve.get(path, {
          headers: {
            "api-key": this.getAuth(),
          },
        });
        this.fixture = response.data.data.map((fixture) => {
          const updatedOutcomes = fixture.outcomes.map((outcome) => {
            return {
              ...outcome,
              picked: false,
            };
          });
          return {
            ...fixture,
            outcomes: updatedOutcomes,
          };
        });
      } catch (error) {
        if (error.response) {
          console.log("Error response:", error.response);
          if ([401, 400, 428].includes(error.response.status)) {
            this.setError(`${this.$t("sessionExpired")}`);
          } else {
            this.setError(error.response.data.message);
          }
        } else {
          // this.setError("An error occurred while fetching data. Please try again later.");
        }
      } finally {
        this.loading = false;
      }
    },

    async placeFreeBets() {
      try {
        this.spinner = true;
        const path = `${process.env.VUE_APP_BASE_FREEBET_URL}/bet`;
        const freeBets = this.getObject("freeBet");
        const freeBet = {
          bet_type: 1,
          bets: [freeBets],
          booking_code: "",
          campaign: "1",
          ip_address: "1",
          medium: "1",
          source: 1,
          stake: 100,
          stake_type: "1",
          utm_source: "1",
        };

        await bettingserve.post(path, freeBet, {
          headers: {
            "api-key": this.getAuth(),
          },
        });
        // this.$router.push("/");
        this.isSuccess = true;
        // this.saveObject("freebet-first", false);
      } catch (error) {
        if (error.response) {
          console.log("Error response:", error.response);

          if ([401, 428].includes(error.response.status)) {
            this.setError(`${this.$t("sessionExpired")}`);
          } else {
            this.setError(error.response.data.error_message);
          }
        }
      } finally {
        this.spinner = false;
      }
    },
  },
  mounted: function () {
    var path = process.env.VUE_APP_CURRENCY;

    console.log(path, "-------------------");

    this.getFreeBets();
    this.getFreeBalance();
    this.saveObject("freeBet", "");
    // let freebet = this.getObject("freebet-first");
    this.$store.dispatch("updateBalance", 0);
    // if (!freebet) {
    // this.$router.push("/");
    // }
    // const msisdn = localStorage.getItem("mssdn__reg_xpk");
    // this.msisdn = msisdn;
  },
  computed: {
    oddsBalance() {
      let roundedToOneDecimal = Number(
        this.$store.state.oddsBalance.toFixed(2)
      );
      return roundedToOneDecimal;
    },
    possibleWin() {
      const cal = this.oddsBalance * this.balance;
      return cal.toFixed(0);
    },
    freebetStake() {
      return this.balance;
    },
    finalPayout() {
      const cal =
        this.oddsBalance * this.balance
          ? this.oddsBalance * this.balance - this.balance
          : this.balance;
      return cal.toFixed(0);
    },
  },
  beforeRouteLeave(to, from, next) {
    // When leaving the page, save `freebet-first` as false
    this.saveObject("freebet-first", false);
    next(); // Proceed with navigation
  },
};
</script>

<style src="./index.css" scoped></style>
