export const metaData = {
  title: "Chopbet Sports Betting - Bet on Your Favorite Sports",
  ogTitle: "Chopbet Sports Betting - Bet on Your Favorite Sports",
  description:
    "Bet on football, basketball, rugby, and more with Chopbet.  Enjoy live betting and win big. Sign up today!",
  ogDescription:
    "Bet on football, basketball, rugby, and more with Chopbet.  Enjoy live betting and win big. Sign up today!",
  keywords:
    "Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score",
  ogImage: "https://dev.chopbet.ci/android-chrome-512x512.png",
  ogUrl: "https://dev.chopbet.ci/",
};
