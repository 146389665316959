<template>
  <div class="wrapper">
    <div>
      <div class="page_container">
        <div class="widgets">
          <iframe :src="statUrl" frameborder="0"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Statistics",
  components: {},
  data() {
    return {
      statUrl: `https://s5.sir.sportradar.com/choplifegamingmts`,
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped src="./index.css"></style>
