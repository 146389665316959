<template>
  <div class="skeleton-wrapper">
    <div v-for="n in count" :key="n">
      <div class="head">
        <div class="skeleton-item"></div>
        <div class="skeleton-item"></div>
      </div>

      <div class="content">
        <div>
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
        </div>
        <div>
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
        </div>
      </div>

      <div class="skeleton_button">
        <div class="possible_win">
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
        </div>
        <div class="possible_win">
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
        </div>
        <div class="possible_win">
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
        </div>
        <div class="skeleton-item btn-skeleton"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FreeBetSkeleton",
  props: {
    count: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style src="./index.css" scoped></style>
