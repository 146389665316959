var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:_vm.buttonClass,style:({
    height:
      _vm.size === 'small'
        ? '40px !important'
        : _vm.casino
        ? '24px!important'
        : '54px!important',
  }),attrs:{"type":_vm.type,"aria-label":_vm.ariaLabel,"disabled":_vm.disabled},on:{"click":_vm.handleClick}},[(_vm.loading)?_c('div',[_c('LoadingSpinner',{attrs:{"size":"30","color":_vm.variant === 'primary' ? '#fff' : '#000'}})],1):_c('div',[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }