<template>
  <div v-if="isVisible">
    <!-- <div :class="isVisible && modal ? 'slide_wrapper modal' : 'slide_wrapper '  "> -->
    <div
      :class="[
        { 'full-modal': disableClose },
        isVisible && modal ? 'slide_wrapper modal' : 'slide_wrapper',
      ]"
    >
      <div @click="closeSlideUp()" class="click_away"></div>
      <div
        :class="isVisible ? 'slide_container' : 'slide_container_up'"
        :style="{
          borderRadius: fullHeight ? '0px' : '16px',
        }"
      >
        <div
          class="scrollable_contents"
          :style="{
            maxHeight: fullHeight ? '' : '85',
            minHeight: fullHeight ? '100' : '',
          }"
        >
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JackpotSlideUp",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    disableClose: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: this.isOpen,
    };
  },
  watch: {
    isOpen(newVal) {
      this.isVisible = newVal;
      if (newVal) {
        document.body.style.overflow = "hidden";
        this.setVhUnit();
      } else {
        document.body.style.overflow = "";
      }
    },
  },
  mounted() {
    this.setVhUnit();
    window.addEventListener("resize", this.setVhUnit);
  },
  beforeDestroy() {
    document.body.style.overflow = "";
    window.removeEventListener("resize", this.setVhUnit);
  },
  methods: {
    closeSlideUp() {
      if (this.disableClose) {
        return;
      }
      this.isVisible = false;
      this.$emit("closeSlideup", false);
      document.body.style.overflow = "";
    },
    setVhUnit() {
      // Fix for iOS where status bar affects viewport height
      // let vh = window.innerHeight * 0.01;
      // document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
  },
};
</script>

<style scoped>
.click_away {
  flex-grow: 1;
}

.full-modal .slide_container {
  height: 95vh;
}
.slide_wrapper {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  left: 0;
  z-index: 100000;
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* border-radius: 16px 16px 0 0; */
  /* curved */
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slide_container {
  height: auto;
  border-radius: 16px 16px 0 0;
  background-color: var(--background-color);
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
  color: var(--text-color);
  padding-bottom: 0rem;
  animation: slideUp 0.5s ease forwards;
  overflow-y: scroll;
  position: relative;
}

.slide_container::-webkit-scrollbar {
  display: none;
}

.slide_container_up {
  height: auto;
  max-height: 500px;
  border-radius: 30px 30px 0 0;
  background-color: #fff;
  width: 100%;
  color: #545151;
  animation: slideDown 0.5s ease forwards;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

.fixed_bottom_content {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 550px;
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
  padding: 0rem 1rem;
  padding-bottom: 5.5rem;

  z-index: 200;
  /* outline: 4px solid red; */
}

.scrollable_contents {
  /* max-height: calc(var(--vh, 1vh) * 85); */
  overflow-y: auto;
}
</style>
