export const pushToDataLayer = (event, eventData) => {
    const elementPath = eventData.element ? getElementPath(eventData.element) : '';
  
    window.dataLayer.push({
      event: event,
      ...eventData,
      gtm: {
        uniqueEventId: new Date().getTime(),
        start: Date.now(),
        element: elementPath
      }
    });
  };
  
  const getElementPath = (element) => {
    if (!element) return '';
    let path = [];
    while (element) {
      let name = element.localName;
      if (!name) break;
      name = name.toLowerCase();
      if (element.id) {
        name += `#${element.id}`;
        path.unshift(name);
        break;
      } else {
        let parent = element.parentNode;
        if (!parent) break;
        let sameTagSiblings = Array.from(parent.children).filter(
          e => e.localName === name
        );
        if (sameTagSiblings.length > 1) {
          let index = sameTagSiblings.indexOf(element) + 1;
          name += `:nth-of-type(${index})`;
        }
      }
      path.unshift(name);
      element = element.parentNode;
    }
    return path.join(' > ');
  };
  