<template>
  <button
    v-bind:id="id"
    :class="['btn odd-btn', statusClass, pick.picked ? 'picked' : '']"
    @click="handlePickBet(pick)"
  >
    <span>{{ getTeamClass(outcome_id) }}</span>
    <span>
      {{ parseFloat(odds).toFixed(2) }}
    </span>
  </button>
</template>

<script>
export default {
  name: "FreeBetOdd",
  data: function () {
    return {
      iconSize: 20,
      fixtureBet: [],
      activeOutcome: "",
    };
  },
  components: {},
  props: {
    pick: {
      required: true,
    },
    away_team: {
      required: true,
    },
    home_team: {
      required: true,
    },
    outcome_name: {
      required: true,
    },
    show_direction: {
      required: false,
      default: true,
    },
    previous_odds: {
      required: false,
    },
    odds: {
      required: true,
    },
    match_id: {
      required: true,
    },
    market_id: {
      required: true,
    },
    outcome_id: {
      required: true,
    },
    specifier: {
      required: true,
    },
    active: {
      required: true,
    },
    producer_status: {
      required: true,
    },
    alias: {
      required: true,
      default: "",
    },
    unique: {
      required: true,
      default: "",
    },
    currentFixture: {
      required: true,
    },
  },
  methods: {
    getTeamClass(alias) {
      if (alias === "1") return "1";
      if (alias === "2") return "X";
      if (alias === "3") return "2";
      return "";
    },
    handlePickBet: function (pick) {
      const { market_id, outcome_id, specifier } = pick;
      const checkBet = {
        market_id,
        match_id: this.match_id,
        outcome_id,
        producer_id: 1,
        specifier,
      };

      const updatedOutcomes = this.currentFixture.outcomes.map((outcome) =>
        outcome.outcome_id === outcome_id
          ? { ...outcome, picked: true }
          : { ...outcome, picked: false }
      );
      this.$set(this.currentFixture, "outcomes", updatedOutcomes);
      this.fixtureBet = checkBet;
      // console.log(first);
      this.$store.dispatch("updateBalance", pick.odds);

      // this.saveObject("oddsBalance", pick.odds);
      this.saveObject("freeBet", this.fixtureBet);
      this.autoRefreshUI("handlePickBet");
    },
  },
  mounted: function () {
    this.odd = this.odds;
  },
  computed: {
    picked: function () {
      const bSlip = this.fixtureBet;
      const pickedBet = bSlip.find((bet) => bet.match_id == this.match_id);
      if (pickedBet && pickedBet.outcome_id === this.outcome_id) {
        return "picked";
      } else {
        return "";
      }
    },
  },
};
</script>

<style src="./index.css" scoped></style>
