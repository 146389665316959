export const alphaCodeData =  [
    { alpha2code: "AF", alpha3code: "AFG" },
    {
      alpha2code: "AL",
      alpha3code: "ALB",
    },
    { alpha2code: "DZ", alpha3code: "DZA" },
    {
      alpha2code: "AS",
      alpha3code: "ASM",
    },
    { alpha2code: "AD", alpha3code: "AND" },
    {
      alpha2code: "AO",
      alpha3code: "AGO",
    },
    { alpha2code: "AI", alpha3code: "AIA" },
    {
      alpha2code: "AG",
      alpha3code: "ATG",
    },
    { alpha2code: "AR", alpha3code: "ARG" },
    {
      alpha2code: "AM",
      alpha3code: "ARM",
    },
    { alpha2code: "AW", alpha3code: "ABW" },
    {
      alpha2code: "AU",
      alpha3code: "AUS",
    },
    { alpha2code: "AT", alpha3code: "AUT" },
    {
      alpha2code: "AZ",
      alpha3code: "AZE",
    },
    { alpha2code: "BH", alpha3code: "BHR" },
    {
      alpha2code: "BD",
      alpha3code: "BGD",
    },
    { alpha2code: "BB", alpha3code: "BRB" },
    {
      alpha2code: "BY",
      alpha3code: "BLR",
    },
    { alpha2code: "BE", alpha3code: "BEL" },
    {
      alpha2code: "BZ",
      alpha3code: "BLZ",
    },
    { alpha2code: "BJ", alpha3code: "BEN" },
    {
      alpha2code: "BM",
      alpha3code: "BMU",
    },
    { alpha2code: "BT", alpha3code: "BTN" },
    {
      alpha2code: "BO",
      alpha3code: "BOL",
    },
    { alpha2code: "BQ", alpha3code: "BES" },
    {
      alpha2code: "BA",
      alpha3code: "BIH",
    },
    { alpha2code: "BW", alpha3code: "BWA" },
    {
      alpha2code: "BR",
      alpha3code: "BRA",
    },
    { alpha2code: "IO", alpha3code: "IOT" },
    {
      alpha2code: "VG",
      alpha3code: "VGB",
    },
    { alpha2code: "BN", alpha3code: "BRN" },
    {
      alpha2code: "BG",
      alpha3code: "BGR",
    },
    { alpha2code: "BF", alpha3code: "BFA" },
    {
      alpha2code: "MM",
      alpha3code: "MMR",
    },
    { alpha2code: "BI", alpha3code: "BDI" },
    {
      alpha2code: "KH",
      alpha3code: "KHM",
    },
    { alpha2code: "CM", alpha3code: "CMR" },
    {
      alpha2code: "CA",
      alpha3code: "CAN",
    },
    { alpha2code: "CV", alpha3code: "CPV" },
    {
      alpha2code: "KY",
      alpha3code: "CYM",
    },
    { alpha2code: "CF", alpha3code: "CAF" },
    {
      alpha2code: "ID",
      alpha3code: "TCD",
    },
    { alpha2code: "CL", alpha3code: "CHL" },
    {
      alpha2code: "CN",
      alpha3code: "CHN",
    },
    { alpha2code: "CO", alpha3code: "COL" },
    {
      alpha2code: "KM",
      alpha3code: "COM",
    },
    { alpha2code: "CK", alpha3code: "COK" },
    {
      alpha2code: "CR",
      alpha3code: "CRI",
    },
    { alpha2code: "CI", alpha3code: "CIV" },
    {
      alpha2code: "HR",
      alpha3code: "HRV",
    },
    { alpha2code: "CU", alpha3code: "CUB" },
    {
      alpha2code: "CW",
      alpha3code: "CUW",
    },
    { alpha2code: "CY", alpha3code: "CYP" },
    {
      alpha2code: "CZ",
      alpha3code: "CZE",
    },
    { alpha2code: "DK", alpha3code: "DNK" },
    {
      alpha2code: "DJ",
      alpha3code: "DJI",
    },
    { alpha2code: "DM", alpha3code: "DMA" },
    {
      alpha2code: "DO",
      alpha3code: "DOM",
    },
    { alpha2code: "EC", alpha3code: "ECU" },
    {
      alpha2code: "EG",
      alpha3code: "EGY",
    },
    { alpha2code: "SV", alpha3code: "SLV" },
    {
      alpha2code: "GQ",
      alpha3code: "GNQ",
    },
    { alpha2code: "ER", alpha3code: "ERI" },
    {
      alpha2code: "EE",
      alpha3code: "EST",
    },
    { alpha2code: "ET", alpha3code: "ETH" },
    {
      alpha2code: "FK",
      alpha3code: "FLK",
    },
    { alpha2code: "FO", alpha3code: "FRO" },
    {
      alpha2code: "FM",
      alpha3code: "FSM",
    },
    { alpha2code: "FJ", alpha3code: "FJI" },
    {
      alpha2code: "FI",
      alpha3code: "FIN",
    },
    { alpha2code: "FR", alpha3code: "FRA" },
    {
      alpha2code: "GF",
      alpha3code: "GUF",
    },
    { alpha2code: "PF", alpha3code: "PYF" },
    {
      alpha2code: "GA",
      alpha3code: "GAB",
    },
    { alpha2code: "GE", alpha3code: "GEO" },
    {
      alpha2code: "DE",
      alpha3code: "DEU",
    },
    { alpha2code: "GH", alpha3code: "GHA" },
    {
      alpha2code: "GI",
      alpha3code: "GIB",
    },
    { alpha2code: "GR", alpha3code: "GRC" },
    {
      alpha2code: "GL",
      alpha3code: "GRL",
    },
    { alpha2code: "GD", alpha3code: "GRD" },
    {
      alpha2code: "GP",
      alpha3code: "GLP",
    },
    { alpha2code: "GU", alpha3code: "GUM" },
    {
      alpha2code: "GT",
      alpha3code: "GTM",
    },
    { alpha2code: "GN", alpha3code: "GIN" },
    {
      alpha2code: "GW",
      alpha3code: "GNB",
    },
    { alpha2code: "GY", alpha3code: "GUY" },
    {
      alpha2code: "HT",
      alpha3code: "HTI",
    },
    { alpha2code: "HN", alpha3code: "HND" },
    {
      alpha2code: "HK",
      alpha3code: "HKG",
    },
    { alpha2code: "HU", alpha3code: "HUN" },
    {
      alpha2code: "IS",
      alpha3code: "ISL",
    },
    { alpha2code: "IN", alpha3code: "IND" },
    {
      alpha2code: "ID",
      alpha3code: "IDN",
    },
    { alpha2code: "IR", alpha3code: "IRN" },
    {
      alpha2code: "IQ",
      alpha3code: "IRQ",
    },
    { alpha2code: "IE", alpha3code: "IRL" },
    {
      alpha2code: "IL",
      alpha3code: "ISR",
    },
    { alpha2code: "IT", alpha3code: "ITA" },
    {
      alpha2code: "JM",
      alpha3code: "JAM",
    },
    { alpha2code: "JP", alpha3code: "JPN" },
    {
      alpha2code: "JO",
      alpha3code: "JOR",
    },
    { alpha2code: "KZ", alpha3code: "KAZ" },
    {
      alpha2code: "KE",
      alpha3code: "KEN",
    },
    { alpha2code: "KI", alpha3code: "KIR" },
    {
      alpha2code: "KW",
      alpha3code: "KWT",
    },
    { alpha2code: "KG", alpha3code: "KGZ" },
    {
      alpha2code: "LA",
      alpha3code: "LAO",
    },
    { alpha2code: "LV", alpha3code: "LVA" },
    {
      alpha2code: "LB",
      alpha3code: "LBN",
    },
    { alpha2code: "LS", alpha3code: "LSO" },
    {
      alpha2code: "LR",
      alpha3code: "LBR",
    },
    { alpha2code: "LY", alpha3code: "LBY" },
    {
      alpha2code: "LI",
      alpha3code: "LIE",
    },
    { alpha2code: "LT", alpha3code: "LTU" },
    {
      alpha2code: "LU",
      alpha3code: "LUX",
    },
    { alpha2code: "MO", alpha3code: "MAC" },
    {
      alpha2code: "MK",
      alpha3code: "MKD",
    },
    { alpha2code: "MG", alpha3code: "MDG" },
    {
      alpha2code: "MW",
      alpha3code: "MWI",
    },
    { alpha2code: "MY", alpha3code: "MYS" },
    {
      alpha2code: "MV",
      alpha3code: "MDV",
    },
    { alpha2code: "ML", alpha3code: "MLI" },
    {
      alpha2code: "MT",
      alpha3code: "MLT",
    },
    { alpha2code: "MH", alpha3code: "MHL" },
    {
      alpha2code: "MQ",
      alpha3code: "MTQ",
    },
    { alpha2code: "MR", alpha3code: "MRT" },
    {
      alpha2code: "MU",
      alpha3code: "MUS",
    },
    { alpha2code: "YT", alpha3code: "MYT" },
    {
      alpha2code: "MX",
      alpha3code: "MEX",
    },
    { alpha2code: "MD", alpha3code: "MDA" },
    {
      alpha2code: "MC",
      alpha3code: "MCO",
    },
    { alpha2code: "MN", alpha3code: "MNG" },
    {
      alpha2code: "ME",
      alpha3code: "MNE",
    },
    { alpha2code: "MS", alpha3code: "MSR" },
    {
      alpha2code: "MA",
      alpha3code: "MAR",
    },
    { alpha2code: "MZ", alpha3code: "MOZ" },
    {
      alpha2code: "NA",
      alpha3code: "NAM",
    },
    { alpha2code: "NR", alpha3code: "NRU" },
    {
      alpha2code: "NP",
      alpha3code: "NPL",
    },
    { alpha2code: "NL", alpha3code: "NLD" },
    {
      alpha2code: "AN",
      alpha3code: "ANT",
    },
    { alpha2code: "NC", alpha3code: "NCL" },
    {
      alpha2code: "NZ",
      alpha3code: "NZL",
    },
    { alpha2code: "NI", alpha3code: "NIC" },
    {
      alpha2code: "NE",
      alpha3code: "NER",
    },
    { alpha2code: "NG", alpha3code: "NGA" },
    {
      alpha2code: "NU",
      alpha3code: "NIU",
    },
    { alpha2code: "NF", alpha3code: "NFK" },
    {
      alpha2code: "KP",
      alpha3code: "PRK",
    },
    { alpha2code: "MP", alpha3code: "MNP" },
    {
      alpha2code: "NO",
      alpha3code: "NOR",
    },
    { alpha2code: "OM", alpha3code: "OMN" },
    {
      alpha2code: "PK",
      alpha3code: "PAK",
    },
    { alpha2code: "PW", alpha3code: "PLW" },
    {
      alpha2code: "PS",
      alpha3code: "PSE",
    },
    { alpha2code: "PA", alpha3code: "PAN" },
    {
      alpha2code: "PG",
      alpha3code: "PNG",
    },
    { alpha2code: "PY", alpha3code: "PRY" },
    {
      alpha2code: "PE",
      alpha3code: "PER",
    },
    { alpha2code: "PH", alpha3code: "PHL" },
    {
      alpha2code: "PL",
      alpha3code: "POL",
    },
    { alpha2code: "PT", alpha3code: "PRT" },
    {
      alpha2code: "PR",
      alpha3code: "PRI",
    },
    { alpha2code: "QA", alpha3code: "QAT" },
    {
      alpha2code: "CG",
      alpha3code: "COG",
    },
    { alpha2code: "RE", alpha3code: "REU" },
    {
      alpha2code: "RO",
      alpha3code: "ROU",
    },
    { alpha2code: "RU", alpha3code: "RUS" },
    {
      alpha2code: "RW",
      alpha3code: "RWA",
    },
    { alpha2code: "BL", alpha3code: "BLM" },
    {
      alpha2code: "SH",
      alpha3code: "SHN",
    },
    { alpha2code: "KN", alpha3code: "KNA" },
    {
      alpha2code: "MF",
      alpha3code: "MAF",
    },
    { alpha2code: "PM", alpha3code: "SPM" },
    {
      alpha2code: "VC",
      alpha3code: "VCT",
    },
    { alpha2code: "WS", alpha3code: "WSM" },
    {
      alpha2code: "SM",
      alpha3code: "SMR",
    },
    { alpha2code: "ST", alpha3code: "STP" },
    {
      alpha2code: "SA",
      alpha3code: "SAU",
    },
    { alpha2code: "SN", alpha3code: "SEN" },
    {
      alpha2code: "RS",
      alpha3code: "SRB",
    },
    { alpha2code: "SC", alpha3code: "SYC" },
    {
      alpha2code: "SL",
      alpha3code: "SLE",
    },
    { alpha2code: "SG", alpha3code: "SGP" },
    {
      alpha2code: "SX",
      alpha3code: "SXM",
    },
    { alpha2code: "SK", alpha3code: "SVK" },
    {
      alpha2code: "SI",
      alpha3code: "SVN",
    },
    { alpha2code: "SB", alpha3code: "SLB" },
    {
      alpha2code: "SO",
      alpha3code: "SOM",
    },
    { alpha2code: "ZA", alpha3code: "ZAF" },
    {
      alpha2code: "KR",
      alpha3code: "KOR",
    },
    { alpha2code: "SS", alpha3code: "SSD" },
    {
      alpha2code: "ES",
      alpha3code: "ESP",
    },
    { alpha2code: "LK", alpha3code: "LKA" },
    {
      alpha2code: "LC",
      alpha3code: "LCA",
    },
    { alpha2code: "SD", alpha3code: "SDN" },
    {
      alpha2code: "SR",
      alpha3code: "SUR",
    },
    { alpha2code: "SZ", alpha3code: "SWZ" },
    {
      alpha2code: "SE",
      alpha3code: "SWE",
    },
    { alpha2code: "CH", alpha3code: "CHE" },
    {
      alpha2code: "SY",
      alpha3code: "SYR",
    },
    { alpha2code: "TW", alpha3code: "TWN" },
    {
      alpha2code: "TJ",
      alpha3code: "TJK",
    },
    { alpha2code: "TZ", alpha3code: "TZA" },
    {
      alpha2code: "TH",
      alpha3code: "THA",
    },
    { alpha2code: "BS", alpha3code: "BHS" },
    {
      alpha2code: "GM",
      alpha3code: "GMB",
    },
    { alpha2code: "TL", alpha3code: "TLS" },
    {
      alpha2code: "TG",
      alpha3code: "TGO",
    },
    { alpha2code: "TK", alpha3code: "TKL" },
    {
      alpha2code: "TO",
      alpha3code: "TON",
    },
    { alpha2code: "TT", alpha3code: "TTO" },
    {
      alpha2code: "TN",
      alpha3code: "TUN",
    },
    { alpha2code: "TR", alpha3code: "TUR" },
    {
      alpha2code: "TM",
      alpha3code: "TKM",
    },
    { alpha2code: "TC", alpha3code: "TCA" },
    {
      alpha2code: "TV",
      alpha3code: "TUV",
    },
    { alpha2code: "UG", alpha3code: "UGA" },
    {
      alpha2code: "UA",
      alpha3code: "UKR",
    },
    { alpha2code: "AE", alpha3code: "ARE" },
    {
      alpha2code: "GB",
      alpha3code: "GBR",
    },
    { alpha2code: "GB", alpha3code: "ENG" },
    {
      alpha2code: "US",
      alpha3code: "USA",
    },
    { alpha2code: "UY", alpha3code: "URY" },
    {
      alpha2code: "VI",
      alpha3code: "VIR",
    },
    { alpha2code: "UZ", alpha3code: "UZB" },
    {
      alpha2code: "VU",
      alpha3code: "VUT",
    },
    { alpha2code: "VA", alpha3code: "VAT" },
    {
      alpha2code: "VE",
      alpha3code: "VEN",
    },
    { alpha2code: "VN", alpha3code: "VNM" },
    {
      alpha2code: "WF",
      alpha3code: "WLF",
    },
    { alpha2code: "YE", alpha3code: "YEM" },
    {
      alpha2code: "ZM",
      alpha3code: "ZMB",
    },
    { alpha2code: "ZW", alpha3code: "ZWE" },
  ];