<template>
  <div class="">
    <div class="page_container">
      <BackComponent :title="$t('languageSettings')" />
    </div>

    <section class="lang">
      <div @click="changeLanguage('en')">
        <div class="box_one">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M19.9156 8.69566H11.3047H11.3046V0.0846484C10.8776 0.0290625 10.4423 0 10.0002 0C9.5581 0 9.12286 0.0290625 8.69591 0.0846484V8.69559V8.69563H0.0848926C0.0293066 9.12262 0.000244141 9.55793 0.000244141 10C0.000244141 10.4421 0.0293066 10.8774 0.0848926 11.3043H8.69583H8.69587V19.9154C9.12286 19.9709 9.5581 20 10.0002 20C10.4423 20 10.8776 19.971 11.3046 19.9154V11.3044V11.3044H19.9156C19.9712 10.8774 20.0002 10.4421 20.0002 10C20.0002 9.55793 19.9712 9.12262 19.9156 8.69566V8.69566Z"
              fill="#D80027"
            />
            <path
              d="M12.6087 12.6094L17.071 17.0718C17.2763 16.8666 17.4721 16.6521 17.6589 16.4298L13.8385 12.6094H12.6087V12.6094Z"
              fill="#D80027"
            />
            <path
              d="M7.39134 12.6094H7.39126L2.92896 17.0717C3.13411 17.2769 3.3486 17.4727 3.57095 17.6595L7.39134 13.839V12.6094Z"
              fill="#D80027"
            />
            <path
              d="M7.39128 7.39215V7.39207L2.92894 2.92969C2.7237 3.13484 2.52792 3.34934 2.34113 3.57168L6.16155 7.39211H7.39128V7.39215Z"
              fill="#D80027"
            />
            <path
              d="M12.6087 7.39004L17.0711 2.92762C16.8659 2.72238 16.6514 2.5266 16.4291 2.33984L12.6087 6.16027V7.39004Z"
              fill="#D80027"
            />
          </svg>
          <span class="text-dark">
            <strong>{{ $t("english") }}</strong>
          </span>
        </div>
        <span>
          <TickCircle v-if="currentLang === 'en'" class="w-2 h-2" />
        </span>
      </div>
      <div @click="changeLanguage('fr')">
        <div class="box_one">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g clip-path="url(#clip0_9291_38050)">
              <path
                d="M9.9997 20C15.5225 20 19.9997 15.5228 19.9997 10C19.9997 4.47715 15.5225 0 9.9997 0C4.47685 0 -0.000305176 4.47715 -0.000305176 10C-0.000305176 15.5228 4.47685 20 9.9997 20Z"
                fill="#F0F0F0"
              />
              <path
                d="M20 9.9991C20 5.69945 17.2862 2.03402 13.4782 0.621094V19.3771C17.2862 17.9642 20 14.2987 20 9.9991Z"
                fill="#D80027"
              />
              <path
                d="M0 9.9991C0 14.2987 2.71379 17.9642 6.52176 19.3771V0.621094C2.71379 2.03402 0 5.69945 0 9.9991Z"
                fill="#0052B4"
              />
            </g>
            <defs>
              <clipPath id="clip0_9291_38050">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span class="text-dark">
            <strong>{{ $t("french") }}</strong>
          </span>
        </div>
        <span>
          <TickCircle v-if="currentLang === 'fr'" class="w-2 h-2" />
        </span>
      </div>
    </section>
  </div>
</template>

<script>
import BackComponent from "../../components/ui/BackComponent.vue";
import TickCircle from "@/components/icons/TickCircleIcon.vue";

export default {
  name: "Language",
  components: {
    BackComponent,
    TickCircle,
  },
  data: function () {
    return {
      currentLang: this.$i18n.locale,
    };
  },

  methods: {
    changeLanguage(lang) {
      localStorage.setItem("locale", lang);
      this.currentLang = lang;
      this.$i18n.locale = lang;
      this.isDropdownVisible = false;
    },
  },
};
</script>

<style scoped>
.lang > div {
  display: flex;
  height: 48px;
  padding: 12px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  /* border-bottom: 1px solid #e8e8e8; */
  border-bottom: 1px solid var(--leaderboard-border);
  cursor: pointer;
  span {
    color: #0e0d0d;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.12px;
  }
}
.box_one {
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
}
</style>
