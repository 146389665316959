export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  }).format(date);
};
export const formatDateTime = (dateString) => {
  const date = new Date(dateString);

  const formattedDate = new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // 24-hour format
  }).format(date);
  return formattedDate.replace(",", "");
};

export function formatBetStatusOrOutcome(type, value) {
  let result = "";

  if (type === "status") {
    if (value === -1) {
      result = "Cancelled";
    } else if (value === 1) {
      result = "Pending";
    }
  } else if (type === "outcome") {
    if (value === 1) {
      result = "Won";
    } else if (value === 0) {
      result = "Lost";
    } else if (value === -1) {
      result = "Pending";
    }
  }
  return result;
}

export function comaFormated(price, lang) {
  let cleanedPrice = price.toString().replace(/[ ,]/g, "");
  if (lang === "fr") {
    return cleanedPrice.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  } else if (lang === "en") {
    return cleanedPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return price;
  }
}
