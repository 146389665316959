<template>
  <button @click="toggleTheme">
    <div class="icon_group">
      <DisplayIcon />
      <span>{{ $t("displayMenu") }}</span>
    </div>
    <span><DarkLightIcon /></span>
  </button>
</template>

<script>
import DisplayIcon from "@/components/icons/DisplayIcon.vue";
import DarkLightIcon from "@/components/icons/DarkLightIcon.vue";

export default {
  name: "ThemeSwitch",
  data() {
    return {
      currentTheme: this.$store.state.theme,
    };
  },
  components: {
    DisplayIcon,
    DarkLightIcon,
  },

  methods: {
    toggleTheme() {
      this.currentTheme = this.currentTheme === "light" ? "dark" : "light";
      this.setTheme(this.currentTheme);
    },
    setTheme(theme) {
      this.$store.dispatch("setTheme", theme);
    },
  },
};
</script>

<style scoped>
button {
  border: none;
  background-color: var(--background-color);
  color: var(--text-color);
  border-bottom: 0.5px solid var(--border);
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.icon_group {
  display: flex;
  align-items: center;
}

.icon_group span {
  margin-left: 8px;
  font-weight: 600;
}
</style>
