/* eslint-disable */
import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import { routes } from "./routes";
import store from "./store/store";
import "bootstrap-notify";
import jQuery from "jquery";
import VueToast from "vue-toast-notification";
import Buffer from "buffer";
// import VueMeta from 'vue-meta';

import i18n from "./locales/i18n";
import { sportData } from "./utils/sportData";
import { alphaCodeData } from "./utils/alphaCodeData";

const Image = () => import("./components/lazyimage");
import "vue-toast-notification/dist/theme-sugar.css";
import DGA from "./dga";

window.dataLayer = window.dataLayer || [];

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const ChevronLeftIcon = () =>
  import(
    /* webpackChunkName: "material-icons" */ "vue-material-design-icons/ChevronLeft.vue"
  );
const ChevronRightIcon = () =>
  import(
    /* webpackChunkName: "material-icons" */ "vue-material-design-icons/ChevronRight.vue"
  );
const Account = () =>
  import(
    /* webpackChunkName: "material-icons" */ "vue-material-design-icons/AccountCircle.vue"
  );

import Cookies from "js-cookie";
import walletserve from "./services/walletserve";

// adds pragmatic play games websockets to vue instance
Vue.prototype.DGA = DGA;

// you should remove this warning after you fix markets and place bet
Vue.config.silent = true;
Vue.use(VueToast);

Vue.filter("currency", function (amount) {
  if (amount == null) {
    amount = 0;
  }
  return amount.toFixed(2).toLocaleString("en-US", {
    style: "currency",
    currency: "CFA",
  });
});

Vue.filter("formatOdds", function (x) {
  if (x === undefined) {
    return 1;
  }
  return parseFloat(x).toFixed(2);
});

Vue.component("arrow-left-icon", ChevronLeftIcon);
Vue.component("arrow-right-icon", ChevronRightIcon);
Vue.component("person-icon", Account);
Vue.component("lazy-image", Image);

const $ = jQuery;
Vue.prototype.jQuery = $;
const EventBus = new Vue();
Vue.prototype.EventBus = EventBus;

Vue.use(VueRouter);
Vue.config.productionTip = true;
Vue.use(VueToast);
Vue.use(VueSweetalert2);
Vue.use(Buffer);

function notif(message, type) {
  $.notify(
    {
      icon: "add_alert",
      message: message,
    },
    {
      type: type,
      timer: 3000,
      placement: {
        from: "top",
        align: "center",
      },
      offset: {
        y: 1,
        x: 0,
      },
      z_index: 9999,
      template: `
      <div class="toast toast-${type}">
        <button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>
        <span data-notify="message" class="message_wrap"><span class="icon"><span></span></span> <span class="message">${message}</span></span>
      </div>`,
    }
  );
}

Vue.mixin({
  methods: {
    /**
     * gets a random value from 0 to the supplied max
     * @param max
     * @returns {number}
     */
    getRandomInt: function (max) {
      return Math.floor(Math.random() * max);
    },

    resetFilters: function () {
      this.$store.dispatch("setHour", -1);
      this.$store.dispatch("setSportID", 1);
      this.$store.dispatch("setMarketID", 0);
      this.$store.dispatch("setCompetitionID", 0);
      this.$store.dispatch("setCategoryID", 0);
      this.$store.dispatch("setUpcomingDate", "");
      this.$store.dispatch("setCountryCode", "");
    },

    randomString: function (length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },

    getClientID: function () {
      var prof = this.getProfile();
      var n = 10;

      if (!prof) {
        return (
          "anonymous-" +
          Math.floor(Math.random() * (9 * Math.pow(10, n))) +
          Math.pow(10, n)
        );
      }

      n = 5;
      return (
        "profile-" +
        prof.id +
        "-" +
        Math.floor(Math.random() * (9 * Math.pow(10, n))) +
        Math.pow(10, n)
      );
    },

    getOddID: function (match, market, outcome, specifier, alias) {
      var id = `${alias}.match-${match}.market-${market}.specifier-${specifier}.outcome-${outcome}`;

      // replace invalid characters in the id, this occurs where specifier includes player or team names with invalid characters like french characters
      id = id.replace(/\|/g, "-and-");
      id = id.replace(/=/g, "-eq-");
      id = id.replaceAll("+", "");
      return id;
    },

    async uxUpdate(payload) {
      var vm = this;
      // console.log(payload, "payload ----");

      if (payload.event === "profile_update") {
        let bal = payload.balance;
        this.$store.dispatch("setBalance", bal);
        // we got a notification, display it on the UI
        if (
          payload.message !== null &&
          payload.message.notification !== null &&
          payload.message.notification !== undefined
        ) {
          var notification = payload.message.notification;
          if (notification !== undefined && notification.type !== undefined) {
            var type = notification.type;
            var title = notification.title;
            var msg = notification.message;
            if (type === "error") {
              this.setError(`${title}: ${msg}`);
            } else if (type === "success") {
              this.setSuccess(`${title}: ${msg}`);
            } else if (type === "warning") {
              this.setWarning(title, msg);
              // this.setSuccess(`${title}: ${msg}`);
            }
          }
        }
        return;
      } else if (payload.event === "bonus_update") {
        let bal = payload.balance;
        // console.log("New updated bonus balance is " + bal);

        // we got a notification, display it on the UI
        if (
          payload.message !== null &&
          payload.message.notification !== null &&
          payload.message.notification !== undefined
        ) {
          notification = payload.message.notification;
          if (notification !== undefined && notification.type !== undefined) {
            type = notification.type;
            title = notification.title;
            msg = notification.message;

            if (type === "error") {
              this.setError(title, msg);
            } else if (type === "success") {
              this.setSuccess(title, msg);
            } else if (type === "warning") {
              this.setWarning(title, msg);
            }
          }
        }

        return;
      }

      // console.log("got message | " + payload.event);

      var procesed = false;
      let currentDate = new Date();

      switch (payload.event) {
        case "producer_status":
          procesed = true;
          var producer_id = parseInt(payload.producer_id);
          var producer_status = parseInt(payload.status);
          var betradar_timestamp = parseInt(payload.betradar_timestamp);

          var ttl = betradar_timestamp - currentDate.getTime();

          // console.log(
          //   `producer_status | producer ${producer_id} | status ${producer_status} | latency ${ttl}ms`
          // );

          this.EventBus.$emit("producer:status", {
            producer_status: producer_status,
            producer_id: producer_id,
          });

          break;

        case "market_status": // this message updates the status of market or odds for a particular
          procesed = true;
          // console.log(JSON.stringify(payload));

          var match_id = parseInt(payload.match_id);
          var betradar_timestamp = parseInt(payload.betradar_timestamp);

          var market_id = payload.id;
          var status = payload.status;
          var specifier = payload.specifiers;
          var outcome = payload.outcomes;

          // if there no outcomes, this message is meant to open or close markets, no odds change in the message
          if (outcome.length === 0) {
            var topic = `status:match-${match_id}:market-${market_id}:specifier-${specifier}`;
            // emit market status
            vm.EventBus.$emit(topic, {
              status: status,
            });

            var ttl = betradar_timestamp - currentDate.getTime();
            // console.log(
            //   `market status | match ${match_id} | marketID ${market_id} | specifier ${specifier} | status ${status} | latency ${ttl}ms`
            // );
          } else {
            vm.jQuery.each(outcome, function (key, val) {
              var outcome_id = val.outcome_id;
              var active = parseInt(val.active);
              var odd_value = val.odds;

              var ux = {
                status: status,
                active: active,
                odds: odd_value,
              };

              var oddID = vm.getOddID(
                match_id,
                market_id,
                outcome_id,
                specifier,
                "odd"
              );

              var topic = "odds:" + oddID;

              // emit odds update message
              vm.EventBus.$emit(topic, ux);

              var ttl = betradar_timestamp - currentDate.getTime();
              // console.log(
              //   `market update | match ${match_id} | marketID ${market_id} | specifier ${specifier} | outcome ${outcome_id} | status ${status} | latency ${ttl}ms`
              // );
            });
          }

          break;

        case "odds_change": // this message updates all the markets for a psecific match
          procesed = true;

          var match_id = parseInt(payload.match_id);
          var betradar_timestamp = parseInt(payload.betradar_timestamp);

          vm.EventBus.$emit(`match:reload:${match_id}`, payload);

          var ttl = betradar_timestamp - currentDate.getTime();
          // console.log(`Odds Change | match ${match_id} | latency ${ttl}ms`);

          break;
      }

      if (!procesed) {
        // console.log(JSON.stringify(payload));
      }
    },

    formatOdds: function (x) {
      if (x === undefined) {
        return 1;
      }

      return parseFloat(x).toFixed(2);
    },

    setBonus(bonus) {
      // Set the bonus value
      this.bonus = bonus;
      this.EventBus.$emit("bonus:updated", bonus);
    },

    getProfile: function () {
      var authData = Cookies.get("a");
      var profileData = Cookies.get("i");

      if (authData && profileData) {
        try {
          var auth = authData.trim();
          var profile = JSON.parse(profileData);

          if (profile && Object.keys(profile).length > 0) {
            // Emit auth and balance separately
            this.EventBus.$emit("profile:auth", { a: auth });
            this.EventBus.$emit("profile:data", {
              id: profile.id,
              first_name: profile.first_name,
              last_name: profile.last_name,
              msisdn: profile.msisdn,
              login_tag: profile.login_tag,
              new_profile: profile.new_profile,
              referral_code: profile.referral_code,
            });

            this.EventBus.$emit("profile:balance", { b1: profile.b1 });

            return {
              a: auth,
              id: profile.id,
              first_name: profile.first_name,
              last_name: profile.last_name,
              msisdn: profile.msisdn,
              login_tag: profile.login_tag,
              new_profile: profile.new_profile,
              referral_code: profile.referral_code,
              b1: profile.b1,
            };
          }
        } catch (err) {
          // console.log(err.message);
          return false;
        }
      }

      return false;
    },

    setProfile: function (profile) {
      Cookies.set("i", JSON.stringify(profile), { expires: 2, secure: false });
      this.EventBus.$emit("profile:balance", {
        b1: profile.b1,
        id: profile.id,
        first_name: profile.first_name,
        last_name: profile.last_name,
        new_profile: profile.new_profile,
        msisdn: profile.msisdn,
        referral_code: profile.referral_code,
      });
    },

    setAuth: function (auth) {
      Cookies.set("a", auth, { expires: 2, secure: false });
    },

    getAuth: function () {
      var p = Cookies.get("a");

      if (p) {
        if (p === "undefined") {
          return false;
        }

        try {
          var data = p.trim();
          if (data.length > 0) {
            return data;
          }
        } catch (err) {
          return false;
        }
      }

      return false;
    },

    removeProfile: function () {
      Cookies.remove("i");
    },

    removeAuth: function () {
      Cookies.remove("a");
    },

    reloadProfile: function () {
      var profile = this.getProfile();
      var vm = this;
      if (!profile) {
        return;
      }
      var path = process.env.VUE_APP_BASE_WALLET_URL + "/balance";
      walletserve
        .get(path, {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          const updatedProfileData = res.data;
          vm.EventBus.$emit("init:mqtt");
          var login_tag = this.getValue("login_tag");
          var current_login_tag = profile.login_tag;
          if (parseInt(login_tag) !== parseInt(current_login_tag)) {
            vm.setError("Session Expired", `${this.$t("sessionExpired")}`);
            vm.logout();
          } else {
            profile.b1 = updatedProfileData.b1;
            profile.b2 = updatedProfileData.b2;
            // console.log(updatedProfileData, "updatedProfileData");
            this.$store.dispatch("setProfileBalance", updatedProfileData.b1);
            this.$store.dispatch(
              "setWidthrawableBalance",
              updatedProfileData.b2
            );
            vm.setProfile(profile);
          }
        })
        .catch((err) => {
          // console.log("Error Caught:", err);
          if (err.response) {
            if (parseInt(err.response.status) === 401) {
              vm.setError("Session Expired", `${this.$t("sessionExpired")}`);
              // console.log("Session expired. Logging out...");
              vm.logout();
            } else if (parseInt(err.response.status) === 400) {
              vm.setError("Bad Request");
            } else {
              vm.setError("Failed", "An unexpected error occurred.");
            }
          } else {
            // console.log("Network error or no response received.");
          }
        });
    },

    isMobile: function () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },

    isDesktop: function () {
      return !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },

    mysqlDate: function (now) {
      //YYYY-MM-DD
      var yyyy = now.getFullYear();
      var months = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ];
      var mm = months[now.getMonth()];
      var dt = now.getDate();
      var dd = dt > 9 ? dt : "0" + dt;
      return yyyy + "-" + mm + "-" + dd;
    },
    getToday: function () {
      return this.mysqlDate(new Date());
    },
    getTomorrow: function () {
      var date = new Date();
      date.setDate(date.getDate() + 1);
      return this.mysqlDate(date);
    },
    daysFromNow: function (days) {
      var date = new Date();
      date.setDate(date.getDate() + days);
      return this.mysqlDate(date);
    },
    getDayOfWeek: function (date) {
      var now = new Date(date);
      // console.log("GOT date as " + now);

      var weeks = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      return weeks[now.getDay()];
    },
    getLongDayOfWeek: function (date) {
      var now = new Date(date);
      // console.log("GOT date as " + now);

      var weeks = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return weeks[now.getDay()];
    },
    formatMatchDate: function (date) {
      if (
        date === false ||
        date === undefined ||
        date === "" ||
        date === null
      ) {
        return "";
      }

      var res = date.replace(" ", "T");
      var d = new Date(res);
      var months = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ];
      var mon = months[d.getMonth()];
      var dt = d.getDate();
      var day = dt > 9 ? dt : "0" + dt;
      var hr = d.getHours();
      var hour = hr > 9 ? hr : "0" + hr;
      var min = d.getMinutes();
      var minutes = min > 9 ? min : "0" + min;
      var formated = day + "/" + mon + ", " + hour + ":" + minutes;
      return formated;
    },
    timeToStart: function (date) {
      if (
        date === false ||
        date === undefined ||
        date === "" ||
        date === null
      ) {
        return "";
      }

      var res = date.replace(" ", "T");
      var d = new Date(res);
      var now = new Date();

      if (now > d) {
        return "";
      }

      var seconds = (d - now) / 1000;

      if (seconds < 60) {
        // console.log(
        //   "GOT difference between " +
        //     date +
        //     " and now " +
        //     now +
        //     " is " +
        //     seconds +
        //     "s "
        // );
        return seconds + " Sec";
      }

      var minutes = parseInt(seconds / 60);
      // console.log(
      //   "GOT difference between " +
      //     date +
      //     " and now " +
      //     now +
      //     " is " +
      //     minutes +
      //     "min"
      // );

      return minutes + " Min";
    },
    formatCurrency: function (num) {
      if (
        num === false ||
        isNaN(parseFloat(num)) ||
        isNaN(num) ||
        num === undefined ||
        num === "" ||
        num === null
      ) {
        return 0;
      }

      num = parseFloat(num).toFixed(2);
      var num_parts = num.toString().split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return num_parts.join(".");
    },
    getSportIcon: function (sport_id) {
      if (sport_id === undefined) {
        return "";
      }

      if (typeof sport_id === String) {
        //sport_id = sport_id.replaceAll("\"", "").trim()
      }

      return (
        process.env.VUE_APP_ASSETS_BASE_URL +
        "/img/sport-icons/" +
        parseInt(sport_id) +
        ".png"
      );
    },
    getSoccerIcon: function () {
      return process.env.VUE_APP_ASSETS_BASE_URL + "/img/sport-icons/1.png";
    },
    showMenu: function () {
      document.getElementById("menu-init").click();
      //this.bestlip_visible = true;
    },
    copyToClipboard: function (text) {
      if (window.clipboardData && window.clipboardData.setData) {
        // IE specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);
      } else if (
        document.queryCommandSupported &&
        document.queryCommandSupported("copy")
      ) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
          return document.execCommand("copy"); // Security exception may be thrown by some browsers.
        } catch (ex) {
          // console.warn("Copy to clipboard failed.", ex);
          return false;
        } finally {
          document.body.removeChild(textarea);
        }
      }
    },
    toTop: function () {
      window.scrollTo(0, 0);
    },

    getURL: function (path) {
      if (path.length > 0) {
        if (path.substring(0, 1) == "/") {
          // remove the first /
          path = path.substring(1);
        }
      }

      return process.env.VUE_APP_ASSETS_BASE_URL + path;
    },

    saveObject: function (key, value) {
      value = JSON.stringify(value);

      if (typeof Storage !== "undefined") {
        localStorage.setItem(key, value);
      } else {
        document.cookie = key + "=" + value;
      }
    },

    setValue: function (key, value) {
      if (typeof Storage !== "undefined") {
        localStorage.setItem(key, value);
      } else {
        document.cookie = key + "=" + value;
      }
    },

    getObject: function (key) {
      if (typeof Storage !== "undefined") {
        var post = localStorage.getItem(key);

        if (post === undefined) {
          return false;
        }

        return JSON.parse(post);
      } else {
        return JSON.parse(this.getCookie(key));
      }
    },

    shortenName: function (name, characters) {
      if (name == undefined) {
        return name;
      }

      name = name.trim();

      if (name.length <= characters) {
        return name;
      }

      return name.substring(0, characters) + "...";
    },

    getValue: function (key) {
      if (typeof Storage !== "undefined") {
        var post = localStorage.getItem(key);
        if (post == "undefined") {
          return "";
        }

        return post;
      } else {
        return this.getCookie(key);
      }
    },

    removeObject: function (key) {
      if (typeof Storage !== "undefined") {
        localStorage.removeItem(key);
      } else {
        document.cookie = key + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      }
    },

    getCookie: function (ckey) {
      var key = ckey + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(key) == 0) {
          return c.substring(key.length, c.length);
        }
      }
      return "";
    },

    /**
     *
     * @param sport_id
     * @param match_id
     * @param market_name
     * @param market_id
     * @param competitor_1
     * @param competitor_2
     * @param picks
     */

    addPick: function (
      sport_id,
      match_id,
      market_name,
      market_id,
      competitor_1,
      competitor_2,
      producer_id,
      specifier,
      picks,
      status,
      live
    ) {
      var producer_id = producer_id;
      var producer_status = picks.producer_status;
      var status = status;
      var active = picks.active;
      this.$store.dispatch("setShowSlip", true);
      if (parseInt(producer_id) === 1 && parseInt(producer_status) === 0) {
        // console.log(
        //   "exiting due to producer_id#" +
        //     producer_id +
        //     " producer_status#" +
        //     producer_status
        // );
        return;
      }

      if (parseInt(active) === 0) {
        // console.log("exiting due to active#" + active + " status#" + status);
        return;
      }

      var bSlip = this.getObject("bslip");

      if (typeof bSlip === "object") {
        //bSlip = Object.entries(bSlip);
      }
      if (!Array.isArray(bSlip)) {
        bSlip = [];
      }

      var isunselect = false;
      picks.match_id = match_id;
      picks.sport_id = sport_id;
      picks.market_name = market_name;
      picks.market_id = market_id;
      picks.competitor_1 = competitor_1;
      picks.competitor_2 = competitor_2;
      picks.producer_id = producer_id;
      picks.specifier = specifier;
      picks.status = status;

      picks.id = this.getOddID(
        match_id,
        market_id,
        picks.outcome_id,
        picks.specifier,
        "odd"
      );
      picks.id2 = this.getOddID(
        match_id,
        market_id,
        picks.outcome_id,
        picks.specifier,
        "boosted"
      );

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      if (bSlip.length > 29) {
        this.setError("Error", "Maximum number of games reached");
        return;
      }

      if (bSlip.length > 0) {
        $.each(bSlip, function (k, v) {
          if (v !== undefined && v !== null) {
            if (v.id === picks.id) {
              // do quadruplet comparison

              isunselect = true;
            }

            if (v.match_id === match_id) {
              delete bSlip[k];
            }
          }
        });
      }

      if (!isunselect) {
        bSlip.push(picks);
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v) {
          bt.push(v);
        }
      });

      // console.log(bt, "bt-------");
      this.saveObject("bslip", bt);
      this.autoRefreshUI("addPick");
    },

    addJackpotPick777: function (
      sport_id,
      match_id,
      market_name,
      market_id,
      competitor_1,
      competitor_2,
      producer_id,
      picks,
      live
    ) {
      var producer_id = producer_id;
      var producer_status = picks.producer_status;
      var status = picks.status;
      var active = picks.active;

      // console.log("Picksssssss", picks, producer_id);

      if (parseInt(producer_id) === 1 && parseInt(producer_status) === 0) {
        // console.log(
        //   "exiting due to producer_id#" +
        //     producer_id +
        //     " producer_status#" +
        //     producer_status
        // );
        return;
      }

      if (parseInt(active) === 0) {
        // console.log("exiting due to active#" + active + " status#" + status);
        return;
      }

      var bSlip = this.getObject("bslip");

      if (typeof bSlip === "object") {
        //bSlip = Object.entries(bSlip);
      }
      if (!Array.isArray(bSlip)) {
        bSlip = [];
      }

      var isunselect = false;
      picks.match_id = match_id;
      picks.sport_id = sport_id;
      picks.market_name = market_name;
      picks.market_id = market_id;
      picks.competitor_1 = competitor_1;
      picks.competitor_2 = competitor_2;
      picks.producer_id = producer_id;

      picks.id = this.getOddID(
        match_id,
        market_id,
        picks.outcome_id,
        picks.specifier,
        "odd"
      );
      picks.id2 = this.getOddID(
        match_id,
        market_id,
        picks.outcome_id,
        picks.specifier,
        "boosted"
      );

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      if (bSlip.length > 29) {
        this.setError("Error", "Maximum number of games reached");
        return;
      }

      if (bSlip.length > 0) {
        $.each(bSlip, function (k, v) {
          if (v !== undefined && v !== null) {
            if (v.id === picks.id) {
              // do quadruplet comparison

              isunselect = true;
            }

            if (v.match_id === match_id) {
              delete bSlip[k];
            }
          }
        });
      }

      if (!isunselect) {
        bSlip.push(picks);
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v) {
          bt.push(v);
        }
      });

      // console.log(bt, "bt-------");
      this.saveObject("bslip", bt);
      this.autoRefreshUI("addJackpotPick");
    },

    addJackpotPick: function (
      sport_id,
      match_id,
      market_name,
      market_id,
      competitor_1,
      competitor_2,
      picks
    ) {
      var status = picks.status;
      var active = picks.active;

      if (parseInt(active) === 0 || parseInt(status) !== 0) {
        // console.log("exiting due to active#" + active + " status#" + status);
        //return
      }

      var odd_id = picks.odd_id;
      var bSlip = this.getObject("jslip");

      // eslint-disable-next-line no-constant-condition
      if (typeof bSlip === "object") {
        //bSlip = Object.entries(bSlip);
      }

      if (!Array.isArray(bSlip)) {
        bSlip = [];
      }

      var isunselect = false;
      picks.match_id = match_id;
      picks.sport_id = sport_id;
      picks.market_name = market_name;
      picks.market_id = market_id;
      picks.competitor_1 = competitor_1;
      picks.competitor_2 = competitor_2;
      picks.id = this.getOddID(
        match_id,
        market_id,
        picks.outcome_id,
        picks.specifier,
        "jpodd"
      );

      // console.log('picked id '+picks.id);

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      if (bSlip.length > 0) {
        $.each(bSlip, function (k, v) {
          if (v !== undefined && v !== null) {
            if (v.odd_id === odd_id) {
              isunselect = true;
            }

            if (v.match_id === match_id) {
              delete bSlip[k];
            }
          }
        });
      }

      if (!isunselect) {
        bSlip.push(picks);
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v) {
          bt.push(v);
        }
      });

      this.saveObject("jslip", bt);

      // console.log("Number of selected odds: " + bt.length);

      this.autoRefreshJackpotUI("addJackpotPick");

      // console.log("After selection: odd_id=" + odd_id + ", isunselect=" + isunselect);
    },
    addFreebetPick: function (
      sport_id,
      match_id,
      market_name,
      market_id,
      competitor_1,
      competitor_2,
      picks
    ) {
      var status = picks.status;
      var active = picks.active;

      if (parseInt(active) === 0 || parseInt(status) !== 0) {
        // console.log("exiting due to active#" + active + " status#" + status);
        //return
      }

      var odd_id = picks.odd_id;
      var bSlip = this.getObject("jslip");

      // eslint-disable-next-line no-constant-condition
      if (typeof bSlip === "object") {
        //bSlip = Object.entries(bSlip);
      }

      if (!Array.isArray(bSlip)) {
        bSlip = [];
      }

      var isunselect = false;
      picks.match_id = match_id;
      picks.sport_id = sport_id;
      picks.market_name = market_name;
      picks.market_id = market_id;
      picks.competitor_1 = competitor_1;
      picks.competitor_2 = competitor_2;
      picks.id = this.getOddID(
        match_id,
        market_id,
        picks.outcome_id,
        picks.specifier,
        "jpodd"
      );

      // console.log('picked id '+picks.id);

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      if (bSlip.length > 0) {
        $.each(bSlip, function (k, v) {
          if (v !== undefined && v !== null) {
            if (v.odd_id === odd_id) {
              isunselect = true;
            }

            if (v.match_id === match_id) {
              delete bSlip[k];
            }
          }
        });
      }

      if (!isunselect) {
        bSlip.push(picks);
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v) {
          bt.push(v);
        }
      });

      this.saveObject("jslip", bt);

      // console.log("Number of selected odds: " + bt.length);

      this.autoRefreshFreebetUI("addFreebetPick");

      // console.log("After selection: odd_id=" + odd_id + ", isunselect=" + isunselect);
    },

    /**
     *
     * @param sport_id
     * @param tournament_id
     * @param market_name
     * @param market_id
     * @param tournament_name
     * @param category_name
     * @param picks
     */
    addOutrightPick: function (
      sport_id,
      tournament_id,
      market_name,
      market_id,
      tournament_name,
      category_name,
      picks
    ) {
      // console.log("at addOutrightPick ");

      var odd_id = picks.odd_id;
      var bSlip = this.getObject("oslip");

      if (!Array.isArray(bSlip)) {
        bSlip = [];
      }

      var isunselect = false;
      picks.match_id = tournament_id;
      picks.sport_id = sport_id;
      picks.market_name = market_name;
      picks.market_id = market_id;
      picks.tournament_name = tournament_name;
      picks.category_name = category_name;

      picks.id = this.getOddID(
        tournament_id,
        market_id,
        picks.outcome_id,
        picks.specifier,
        "outright"
      );

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      if (bSlip.length > 0) {
        $.each(bSlip, function (k, v) {
          if (v !== undefined && v !== null) {
            if (v.odd_id === odd_id) {
              isunselect = true;
            }

            if (v.match_id === tournament_id) {
              delete bSlip[k];
            }
          }
        });
      }

      // console.log("GOT length of isunselect " + isunselect);

      if (!isunselect) {
        bSlip.push(picks);
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v) {
          bt.push(v);
        }
      });

      this.saveObject("oslip", bt);
      this.autoRefreshOutrightUI("addOutrightPick");
    },

    updateOdd: function (odd_id, odds, previous_odds, active, status) {
      var hasChanged = false;

      if (parseFloat(odds) !== parseFloat(previous_odds)) {
        hasChanged = true;
      }

      var bSlip = this.getObject("bslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (parseInt(v.odd_id) === parseInt(odd_id)) {
          v.odd = odds;
          v.odds = odds;

          if (parseInt(v.active) !== parseInt(active)) {
            hasChanged = true;
          }

          if (parseInt(v.status) !== parseInt(status)) {
            hasChanged = true;
          }

          v.active = active;
          v.status = status;
          v.previous_odds = previous_odds;

          // console.log(
          //   "previous_odds " +
          //     previous_odds +
          //     " new odds " +
          //     odds +
          //     " id " +
          //     v.odd_id +
          //     " received "
          // );
        }

        bt.push(v);
        //}
      });

      if (hasChanged) {
        this.saveObject("bslip", bt);
        this.autoRefreshUI("updateOdd");
      }
      // }
    },

    updateJackpotOdd: function (id, odds, previous_odds, active, status) {
      var hasChanged = false;

      if (parseFloat(odds) !== parseFloat(previous_odds)) {
        hasChanged = true;
      }

      var bSlip = this.getObject("jslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v !== null) {
          if (v.id === id) {
            v.odd = odds;
            v.odds = odds;

            if (parseInt(v.active) !== parseInt(active)) {
              hasChanged = true;
            }

            if (parseInt(v.status) !== parseInt(status)) {
              hasChanged = true;
            }

            v.active = active;
            v.status = status;
            v.previous_odds = previous_odds;
          }

          bt.push(v);
        }
      });

      if (hasChanged) {
        this.saveObject("jslip", bt);
        this.autoRefreshJackpotUI("updateJackpotOdd");
      }
      // }
    },

    updateOutrightOdd: function (id, odds, previous_odds, active, status) {
      var hasChanged = false;

      if (parseFloat(odds) !== parseFloat(previous_odds)) {
        hasChanged = true;
      }

      var bSlip = this.getObject("oslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v !== null) {
          if (v.id === id) {
            v.odd = odds;
            v.odds = odds;

            if (parseInt(v.active) !== parseInt(active)) {
              hasChanged = true;
            }

            if (parseInt(v.status) !== parseInt(status)) {
              hasChanged = true;
            }

            v.active = active;
            v.status = status;
            v.previous_odds = previous_odds;
          }

          bt.push(v);
        }
      });

      if (hasChanged) {
        this.saveObject("oslip", bt);
        this.autoRefreshOutrightUI("updateOutrightOdd");
      }
      // }
    },

    hasSuspendedPicks: function () {
      var bSlip = this.getObject("bslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var suspended = 0;

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v !== null) {
          //a suspended/deactivated outcome has active = 0 and status > 0
          if (parseInt(v.active) !== 1 || parseInt(v.status) !== 0) {
            suspended++;
          }
        }
      });

      return suspended > 0;
    },

    hasSuspendedJackpotPicks: function () {
      var bSlip = this.getObject("jslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var suspended = 0;

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v !== null) {
          //a suspended/deactivated outcome has active = 0 and status > 0
          if (parseInt(v.active) !== 1 || parseInt(v.status) !== 0) {
            suspended++;
          }
        }
      });

      return suspended > 0;
    },

    hasSuspendedFreebetPicks: function () {
      var bSlip = this.getObject("fslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var suspended = 0;

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v !== null) {
          //a suspended/deactivated outcome has active = 0 and status > 0
          if (parseInt(v.active) !== 1 || parseInt(v.status) !== 0) {
            suspended++;
          }
        }
      });

      return suspended > 0;
    },

    hasSuspendedOutrightPicks: function () {
      var bSlip = this.getObject("oslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var suspended = 0;

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v !== null) {
          //a suspended/deactivated outcome has active = 0 and status > 0
          if (parseInt(v.active) !== 1 || parseInt(v.status) !== 0) {
            suspended++;
          }
        }
      });

      return suspended > 0;
    },

    removeSuspendedPicks: function () {
      var bSlip = this.getObject("bslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v !== null) {
          //a suspended/deactivated outcome has active = 0 and status > 0
          if (parseInt(v.active) === 1 && parseInt(v.status) === 0) {
            bt.push(v);
          }
        }
      });

      this.saveObject("bslip", bt);
      this.autoRefreshUI("removeSuspendedPicks");
    },

    removeSuspendedJackpotPicks: function () {
      var bSlip = this.getObject("jslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v !== null) {
          //a suspended/deactivated outcome has active = 0 and status > 0
          if (parseInt(v.active) === 1 && parseInt(v.status) === 0) {
            bt.push(v);
          }
        }
      });

      this.saveObject("jslip", bt);
      this.autoRefreshJackpotUI("removeSuspendedJackpotPicks");
    },

    removeSuspendedOutrightPicks: function () {
      var bSlip = this.getObject("oslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (v !== undefined && v !== null) {
          //a suspended/deactivated outcome has active = 0 and status > 0
          if (parseInt(v.active) === 1 && parseInt(v.status) === 0) {
            bt.push(v);
          }
        }
      });

      this.saveObject("oslip", bt);
      this.autoRefreshOutrightUI("removeSuspendedPicks");
    },

    computeWinnings: function (odds) {
      if (isNaN(odds)) {
        $("#betslip-footer").addClass("hidden");
        $(".slip-counter").html("0");
      }

      var stakeBeforeTax = stake;

      var taxOnStake = 12.5;

      // var stake = stakeBeforeTax / 1.2;
      var stake = stakeBeforeTax - (stakeBeforeTax * taxOnStake) / 100;

      var excise = stakeBeforeTax - stake;

      var bonus = (stake * 10) / 100;

      var total = stake * odds;

      if (total > 1000500) {
        total = 1000500;
      }

      var winning = odds * stake;
      if (winning > 1000000) {
        winning = 1000000;
      }
      var netWinning = winning - stake;
      var withholdingTax = netWinning * 0.2;
      var cashout = winning - withholdingTax;

      // var winnings = total;
      //var tax = 20 * (winnings - stake) / 100;
      //net = (winnings - tax);

      $("#possible_win").html(winning.toFixed(2).toLocaleString());
      $("#stake_after_tax").html(stake.toFixed(2).toLocaleString());
      $("#excise_tax").html(excise.toFixed(2).toLocaleString());
      $("#bonus").html(bonus.toFixed(2).toLocaleString());
      $("#net_win").html(cashout.toFixed(2).toLocaleString());
      $("#win").html(cashout.toFixed(2).toLocaleString());
      $("#total_odd_value").html(parseFloat(odds).toFixed(2).toLocaleString());
      $("#withold_tax").html(withholdingTax.toFixed(2).toLocaleString());
    },
    computeJackpotWinnings: function (odds) {
      if (isNaN(odds)) {
        $("#jackpot-betslip-footer").addClass("hidden");
        $(".jackpot-slip-counter").html("0");
      }

      var stakeBeforeTax = stake;

      var taxOnStake = 12.5;

      // var stake = stakeBeforeTax / 1.2;
      var stake = stakeBeforeTax - (stakeBeforeTax * taxOnStake) / 100;

      var excise = stakeBeforeTax - stake;

      var bonus = (stake * 10) / 100;

      var total = stake * odds;

      if (total > 1000500) {
        total = 1000500;
      }

      var winning = odds * stake;
      if (winning > 1000000) {
        winning = 1000000;
      }
      var netWinning = winning - stake;
      var withholdingTax = netWinning * 0.2;
      var cashout = winning - withholdingTax;

      // var winnings = total;
      //var tax = 20 * (winnings - stake) / 100;
      //net = (winnings - tax);

      $("#jackpot-possible_win").html(winning.toFixed(2).toLocaleString());
      $("#jackpot-stake_after_tax").html(stake.toFixed(2).toLocaleString());
      $("#jackpot-excise_tax").html(excise.toFixed(2).toLocaleString());
      $("#jackpot-bonus").html(bonus.toFixed(2).toLocaleString());
      $("#jackpot-net_win").html(cashout.toFixed(2).toLocaleString());
      $("#jackpot-win").html(cashout.toFixed(2).toLocaleString());
      $("#jackpot-total_odd_value").html(
        parseFloat(odds).toFixed(2).toLocaleString()
      );
      $("#jackpot-withold_tax").html(
        withholdingTax.toFixed(2).toLocaleString()
      );
    },

    computeOutrightWinnings: function (odds) {
      if (isNaN(odds)) {
        $("#outright-betslip-footer").addClass("hidden");
        $(".outright-slip-counter").html("0");
      }

      var stakeBeforeTax = stake;

      var taxOnStake = 12.5;

      // var stake = stakeBeforeTax / 1.2;
      var stake = stakeBeforeTax - (stakeBeforeTax * taxOnStake) / 100;

      var excise = stakeBeforeTax - stake;

      var bonus = (stake * 10) / 100;

      var total = stake * odds;

      if (total > 1000500) {
        total = 1000500;
      }

      var winning = odds * stake;
      if (winning > 1000000) {
        winning = 1000000;
      }
      var netWinning = winning - stake;
      var withholdingTax = netWinning * 0.2;
      var cashout = winning - withholdingTax;

      $("#outright-possible_win").html(winning.toFixed(2).toLocaleString());
      $("#outright-stake_after_tax").html(stake.toFixed(2).toLocaleString());
      $("#outright-excise_tax").html(excise.toFixed(2).toLocaleString());
      $("#outright-bonus").html(bonus.toFixed(2).toLocaleString());
      $("#outright-net_win").html(cashout.toFixed(2).toLocaleString());
      $("#outright-win").html(cashout.toFixed(2).toLocaleString());
      $("#outright-total_odd_value").html(
        parseFloat(odds).toFixed(2).toLocaleString()
      );
      $("#outright-withold_tax").html(
        withholdingTax.toFixed(2).toLocaleString()
      );
    },

    checkSlipCounter: function (slips) {
      if (isNaN(slips)) {
        slips = 0;
      }

      slips = parseInt(slips);

      if (slips === 0) {
        $("#betslip-footer").addClass("hidden");
        $(".slip-counter").html("0");
        $("#slip_c").html("0");
        //$("#counter").html("0");
      } else {
        $("#betslip-footer").removeClass("hidden");

        if (slips > 30) {
          $("#slip_c").html(30);
          $(".slip-counter").html(30);
        } else {
          $("#slip_c").html(slips);
          $(".slip-counter").html(slips);
        }
      }
    },

    checkJackpotSlipCounter: function (slips) {
      if (isNaN(slips)) {
        slips = 0;
      }

      slips = parseInt(slips);

      if (slips === 0) {
        $("#jackpot-betslip-footer").addClass("hidden");
        $(".jackpot-slip-counter").html("0");
        $("#jackpot-slip_c").html("0");
        //$("#counter").html("0");
      } else {
        $("#jackpot-betslip-footer").removeClass("hidden");

        if (slips > 19) {
          $("#jackpot-slip_c").html(19);
          $(".jackpot-slip-counter").html(19);
        } else {
          $("#jackpot-slip_c").html(slips);
          $(".jackpot-slip-counter").html(slips);
        }
      }
    },

    checkOutrightSlipCounter: function (slips) {
      if (isNaN(slips)) {
        slips = 0;
      }

      slips = parseInt(slips);

      if (slips === 0) {
        $("#outright-betslip-footer").addClass("hidden");
        $(".outright-slip-counter").html("0");
        $("#outright-slip_c").html("0");
      } else {
        $("#outright-betslip-footer").removeClass("hidden");

        if (slips > 19) {
          $("#outright-slip_c").html(19);
          $(".outright-slip-counter").html(19);
        } else {
          $("#outright-slip_c").html(slips);
          $(".outright-slip-counter").html(slips);
        }
      }
    },

    /**
     * removes matchID from betslip
     * @param match_id
     */
    removePick: function (match_id) {
      var bSlip = this.getObject("bslip");
      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }
      if (bSlip.length > 0) {
        $.each(bSlip, function (k, v) {
          if (v !== undefined && v !== null) {
            if (v.match_id === match_id) {
              delete bSlip[k];
            }
          }
        });
      }
      var bt = [];
      $.each(bSlip, function (k, v) {
        if (v !== undefined || v) {
          bt.push(v);
        }
      });

      this.saveObject("bslip", bt);
      this.autoRefreshUI("removePick");

      if (bt.length === 0) {
        $(".faso-close").click();
      }
    },

    removeAllPicks: function () {
      var bSlip = this.getObject("bslip");
      if (!bSlip || bSlip === "" || bSlip === null) {
        bSlip = [];
      }
      bSlip = [];
      this.saveObject("bslip", bSlip);
      this.autoRefreshUI("removeAllPicks");
      $(".faso-close").click();
    },

    /**
     * removes matchID from jackpotbetslip
     * @param match_id
     */
    removeJackpotPick: function (match_id) {
      var bSlip = this.getObject("jslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      if (bSlip.length > 0) {
        $.each(bSlip, function (k, v) {
          if (v !== undefined && v !== null) {
            if (v.match_id === match_id) {
              delete bSlip[k];
            }
          }
        });
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (v !== undefined || v) {
          bt.push(v);
        }
      });

      this.saveObject("jslip", bt);
      this.autoRefreshJackpotUI("removeJackpotPick");

      if (bt.length === 0) {
        $(".faso-close").click();
      }
    },

    /**
     * removes tournamentID from betslip
     * @param tournament_id
     */
    removeOutrightPick: function (tournament_id) {
      var bSlip = this.getObject("oslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      if (bSlip.length > 0) {
        $.each(bSlip, function (k, v) {
          if (v !== undefined && v !== null) {
            if (v.tournament_id === tournament_id) {
              delete bSlip[k];
            }
          }
        });
      }

      var bt = [];

      $.each(bSlip, function (k, v) {
        if (v !== undefined || v) {
          bt.push(v);
        }
      });

      this.saveObject("oslip", bt);
      this.autoRefreshUI("removeOutrightPick");

      if (bt.length === 0) {
        $(".faso-close").click();
      }
    },

    /**
     * gets betslip
     * @returns {{}}
     */
    getBetSlip: function (stake) {
      var bSlip = this.getObject("bslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var total = bSlip.length;

      if (total === undefined) {
        total = Object.keys(bSlip).length;
      }

      var bs = {};
      var totalOdds = parseFloat(1);

      if (total > 0) {
        // console.log('USe this betslip to get odds '+JSON.stringify(bSlip, undefined, 2))

        this.jQuery.each(bSlip, function (k, v) {
          bs[v.match_id] = v;
          var odds = parseFloat(v.odds);
          totalOdds = totalOdds * odds;
        });
      }

      // get total odds

      if (stake === 0) {
        stake = this.getValue("stake");
      }

      if (parseInt(stake) === 0) {
        //stake = 50;
      }

      var taxOnStake = 12.5;

      var wagerTax = (stake * taxOnStake) / 100;

      // var stake = stakeBeforeTax / 1.2;
      var stakeAftertax = stake - wagerTax;

      var winning = stakeAftertax * totalOdds;

      if (winning > 1000500) {
        winning = 1000500;
      }

      var netWinning = winning - stakeAftertax;
      var withholdingTax = netWinning * 0.2;
      var payout = winning - withholdingTax;

      var res = {};
      res.picks = bs;
      res.payout = payout;
      res.stake = stake;
      res.wager_tax = wagerTax;
      res.stake_after_tax = stakeAftertax;
      res.winning = winning;
      res.tax = withholdingTax;
      res.total = total;
      res.odds = parseFloat(totalOdds).toFixed(3);
      return res;
    },

    /**
     * gets jackpot betslip
     * @returns {{}}
     */
    getJackpotBetSlip: function (stake) {
      var bSlip = this.getObject("jslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var total = bSlip.length;

      if (total === undefined) {
        total = Object.keys(bSlip).length;
      }

      var bs = {};
      var totalOdds = parseFloat(1);

      if (total > 0) {
        this.jQuery.each(bSlip, function (k, v) {
          bs[v.match_id] = v;
          var odds = parseFloat(v.odd);
          totalOdds = totalOdds * odds;
        });
      }

      // get total odds

      if (stake === 0) {
        stake = this.getValue("stake");
      }

      if (parseInt(stake) === 0) {
        //stake = 50;
      }

      var taxOnStake = 12.5;

      var wagerTax = (stake * taxOnStake) / 100;

      // var stake = stakeBeforeTax / 1.2;
      var stakeAftertax = stake - wagerTax;

      var winning = stakeAftertax * totalOdds;

      if (winning > 1000500) {
        winning = 1000500;
      }

      var netWinning = winning - stakeAftertax;
      var withholdingTax = netWinning * 0.2;
      var payout = winning - withholdingTax;

      var res = {};
      res.picks = bs;
      res.payout = payout;
      res.stake = stake;
      res.wager_tax = wagerTax;
      res.stake_after_tax = stakeAftertax;
      res.winning = winning;
      res.tax = withholdingTax;
      res.total = total;
      res.odds = parseFloat(totalOdds).toFixed(3);
      return res;
    },

    getFreeBetSlip: function (stake) {
      var bSlip = this.getObject("fslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var total = bSlip.length;

      if (total === undefined) {
        total = Object.keys(bSlip).length;
      }

      var bs = {};
      var totalOdds = parseFloat(1);

      if (total > 0) {
        this.jQuery.each(bSlip, function (k, v) {
          bs[v.match_id] = v;
          var odds = parseFloat(v.odd);
          totalOdds = totalOdds * odds;
        });
      }

      // get total odds

      if (stake === 0) {
        stake = this.getValue("stake");
      }

      if (parseInt(stake) === 0) {
        //stake = 50;
      }

      var taxOnStake = 12.5;

      var wagerTax = (stake * taxOnStake) / 100;

      // var stake = stakeBeforeTax / 1.2;
      var stakeAftertax = stake - wagerTax;

      var winning = stakeAftertax * totalOdds;

      if (winning > 1000500) {
        winning = 1000500;
      }

      var netWinning = winning - stakeAftertax;
      var withholdingTax = netWinning * 0.2;
      var payout = winning - withholdingTax;

      var res = {};
      res.picks = bs;
      res.payout = payout;
      res.stake = stake;
      res.wager_tax = wagerTax;
      res.stake_after_tax = stakeAftertax;
      res.winning = winning;
      res.tax = withholdingTax;
      res.total = total;
      res.odds = parseFloat(totalOdds).toFixed(3);
      return res;
    },

    /**
     * gets outright betslip
     * @returns {{}}
     */
    getOutrightBetSlip: function (stake) {
      var bSlip = this.getObject("oslip");

      if (!bSlip || bSlip === "" || bSlip === "") {
        bSlip = [];
      }

      var total = bSlip.length;

      if (total === undefined) {
        total = Object.keys(bSlip).length;
      }

      var bs = {};
      var totalOdds = parseFloat(1);

      if (total > 0) {
        this.jQuery.each(bSlip, function (k, v) {
          bs[v.tournament_id] = v;
          var odds = parseFloat(v.odd);
          totalOdds = totalOdds * odds;
        });
      }

      // get total odds

      if (stake === 0) {
        stake = this.getValue("stake");
      }

      if (parseInt(stake) === 0) {
        //stake = 50;
      }

      var taxOnStake = 12.5;

      var wagerTax = (stake * taxOnStake) / 100;

      // var stake = stakeBeforeTax / 1.2;
      var stakeAftertax = stake - wagerTax;

      var winning = stakeAftertax * totalOdds;

      if (winning > 1000500) {
        winning = 1000500;
      }

      var netWinning = winning - stakeAftertax;
      var withholdingTax = netWinning * 0.2;
      var payout = winning - withholdingTax;

      var res = {};
      res.picks = bs;
      res.payout = payout;
      res.stake = stake;
      res.wager_tax = wagerTax;
      res.stake_after_tax = stakeAftertax;
      res.winning = winning;
      res.tax = withholdingTax;
      res.total = total;
      res.odds = parseFloat(totalOdds).toFixed(3);
      return res;
    },

    /**
     * clears a  betslip
     */
    clearBetSlip: function () {
      this.saveObject("bslip", []);
      this.saveObject("betslip", []);
      this.autoRefreshUI("clearBetSlip");
      this.EventBus.$emit("event:betslip:hide");
      this.$store.dispatch("setBetSlip", []);
    },

    /**
     * clears a jackpot  betslip
     */
    clearJackpotBetSlip: function () {
      this.saveObject("jslip", []);
      this.saveObject("betslip", []);
      this.autoRefreshJackpotUI("clearJackpotBetSlip");
      this.EventBus.$emit("event:jackpotbetslip:hide");
      this.$store.dispatch("setJackpotBetSlip", []);
    },

    /**
     * clears outright  betslip
     */
    clearOutrightBetSlip: function () {
      this.saveObject("oslip", []);
      this.saveObject("betslip", []);
      this.autoRefreshUI("clearOutrightBetSlip");
      this.EventBus.$emit("event:outrightbetslip:hide");
      this.$store.dispatch("setOutrightBetSlip", []);
    },

    autoRefreshUI: function (callersName) {
      // console.log("autoRefreshUI from " + callersName);
      var vm = this;
      var betsObject = this.getBetSlip(0);
      var bets = betsObject.picks;

      // vm.jQuery(".picked").removeClass("picked");

      $.each(bets, function (matchID, b) {
        var oddsClass = "#" + b.odd_id;
        // vm.jQuery(oddsClass).addClass("picked");

        oddsClass = "#boosted-" + b.odd_id;
        vm.jQuery(oddsClass).addClass("picked");
      });

      // console.log('processed betslips '+JSON.stringify(betsObject, undefined, 2));

      this.saveObject("betslip", betsObject);

      this.$store.dispatch("setBetSlip", betsObject);
      this.$store.dispatch("setHasSuspendedPicks", this.hasSuspendedPicks());

      this.checkSlipCounter(betsObject.total);
    },

    autoRefreshJackpotUI: function (callersName) {
      // console.log("autoJackpotRefreshUI from " + callersName)
      var vm = this;

      var betsObject = this.getJackpotBetSlip(0);
      var bets = betsObject.picks;

      vm.jQuery(".jp-picked").removeClass("jp-picked");

      $.each(bets, function (matchID, b) {
        var oddsClass =
          "#" +
          vm.getOddID(matchID, b.market_id, b.outcome_id, b.specifier, "jpodd");
        // console.log('got picked buttonID '+oddsClass);
        vm.jQuery(oddsClass).addClass("jp-picked");
      });

      this.saveObject("jackpotbetslip", betsObject);

      this.$store.dispatch("setJackpotBetSlip", betsObject);
      this.$store.dispatch(
        "setHasSuspendedJackpotPicks",
        this.hasSuspendedJackpotPicks()
      );

      this.checkJackpotSlipCounter(betsObject.total);
    },

    autoRefreshFreebetUI: function (callersName) {
      // console.log("autoJackpotRefreshUI from " + callersName)
      var vm = this;

      var betsObject = this.getFreeBetSlip(30);
      var bets = betsObject.picks;

      vm.jQuery(".picked").removeClass("picked");

      $.each(bets, function (matchID, b) {
        var oddsClass =
          "#" +
          vm.getOddID(matchID, b.market_id, b.outcome_id, b.specifier, "fodd");
        // console.log('got picked buttonID '+oddsClass);
        vm.jQuery(oddsClass).addClass("picked");
      });

      this.saveObject("freebetslip", betsObject);

      this.$store.dispatch("setFreebetBetSlip", betsObject);
      this.$store.dispatch(
        "setHasSuspendedFreebetPicks",
        this.hasSuspendedFreebetPicks()
      );

      // this.checkFreebetSlipCounter(betsObject.total);
    },

    autoRefreshOutrightUI: function (callersName) {
      // console.log("autoRefreshOutrightUI from " + callersName);
      var vm = this;
      vm.jQuery(".picked").removeClass("picked");
      var betsObject = this.getOutrightBetSlip(0);
      var bets = betsObject.picks;

      $.each(bets, function (matchID, b) {
        var oddsClass = "#" + b.odd_id;
        vm.jQuery(oddsClass).addClass("picked");
      });

      this.saveObject("outrightbetslip", betsObject);

      this.$store.dispatch("setOutrightBetSlip", betsObject);
      this.$store.dispatch(
        "setHasSuspendedOutrightPicks",
        this.hasSuspendedOutrightPicks()
      );

      this.checkOutrightSlipCounter(betsObject.total);
    },

    goBack: function () {
      this.$router.back();
    },

    goHome: function () {
      this.goTo("home");
    },

    setError: function (body) {
      this.notify(body, "danger");
    },

    setWarning: function (body) {
      this.notify(body, "danger");
    },

    setSuccess: function (body) {
      this.notify(body, "success");
    },

    reset: function () {
      this.$store.dispatch("resetAlerts");
    },

    getSportName: function (sportID) {
      if (sportID === undefined) {
        return "";
      }

      var sports = sportData;
      var sport = [];

      $.each(sports, function (k, v) {
        if (parseInt(v.sport_id) === parseInt(sportID)) {
          //var s = v.sport_name.replaceAll("\"", "").trim();
        }

        sport[v.sport_id] = v;
      });

      if (sport[sportID] === undefined) {
        return "";
      }

      return sport[sportID].sport_name;
    },

    getCountryFlag: function (country_code) {
      var countryCodeLowerCase = this.getApha2Code(country_code).toLowerCase();
      var flag =
        process.env.VUE_APP_FLAGS_BASE_URL +
        (countryCodeLowerCase !== "unknown"
          ? countryCodeLowerCase
          : "default") +
        ".svg";
      return flag;
    },

    getApha2Code: function (alpha3Code) {
      var r = alphaCodeData;
      var n = 0;
      var max = r.length;

      if (alpha3Code === "ENG") {
        return "GB";
      }

      if (alpha3Code === "NIR") {
        return "GB-NIR";
      }

      if (alpha3Code === "SCO") {
        return "GB-SCT";
      }

      while (n < max) {
        var o = r[n];
        if (o.alpha3code === alpha3Code) {
          return o.alpha2code;
        }

        n++;
      }

      return "WORLD";
    },

    logout: function () {
      this.closeMenu();
      // set profile to false
      this.removeProfile();

      this.removeAuth();

      // reset betslip
      this.clearBetSlip();
      // reset alerts
      this.reset();
      // if (this.$route.path !== "/home") this.$router.push("/home")
      // this.$router.push({ name: "", params: {} });
      window.location.href = "/";
      localStorage.removeItem("x_acc_v_payload1");
      // this.setSuccess(this.$t("logoutSuccess"));
    },

    getURLParameters: function () {
      var query = window.location.search.substring(1);

      var vars = query.split("&");

      var query_string = {};

      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        // If first entry with this name
        if (typeof query_string[pair[0]] === "undefined") {
          query_string[pair[0]] = decodeURIComponent(pair[1]);
          // If second entry with this name
        } else if (typeof query_string[pair[0]] === "string") {
          var arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
          query_string[pair[0]] = arr;
          // If third or later entry with this name
        } else {
          query_string[pair[0]].push(decodeURIComponent(pair[1]));
        }
      }

      return query_string;
    },
    goTo: function (Name) {
      try {
        //this.dismiss();
        this.closeMenu();

        Name = Name.toLowerCase();

        if (this.$store.state.current_page === Name) return;

        this.$router.push({ name: Name });
      } catch (e) {
        // console.log(e.message);
      }
    },
    sortObject: function (obj) {
      return Object.keys(obj)
        .sort(function (a, b) {
          return parseFloat(a) - parseFloat(b);
        })
        .reduce(function (result, key) {
          result[key] = obj[key];
          return result;
        }, {});
    },
    notify: function (message, type) {
      //no-undef
      notif(message, type);
    },
    closeMenu: function () {
      var el = document.getElementById("menu-close");
      if (el) {
        el.click();
      }
    },
    formatStake: function (num) {
      if (
        num === false ||
        isNaN(parseFloat(num)) ||
        isNaN(num) ||
        num === undefined ||
        num === "" ||
        num === null
      ) {
        return 0;
      }

      num = parseFloat(num).toFixed(0);
      var num_parts = num.toString().split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return num_parts.join(".");
    },
    isLoggedInCasino: function () {
      var p = this.getAuth();
      if (!p) {
        return false;
      }

      return true;
    },
    casinoLaunchUrl: function (launch) {
      if (launch === "") {
        this.goTo("home");
      } else {
        return launch;
      }
    },
    launchCasino() {
      return this.casinoLaunchUrl();
    },

    //generates gameurl for all casino games
  },
});

const router = new VueRouter({
  mode: "history",
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //   if (to.hash) {
  //     return {
  //       selector: to.hash,
  //     };
  //   } else {
  //     return { x: 0, y: 0 };
  //   }
  // },

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  if (!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

if (process.env.NODE_ENV === "production") {
  // Load Google Analytics script only in production
  const script = document.createElement("script");
  script.async = true;
  script.src = "https://www.googletagmanager.com/gtag/js?id=G-GQP727304W";
  document.head.appendChild(script);

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-GQP727304W");
  };
}

var v = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount("#app");

if (!v) {
  window.location.href = "https://www.chopbet.ci";
}
