<template>
  <div class="flex relative items-center justify-end">
    <div class="relative z-10 items-start">
      <div class="toggle" @click="toggleDropdown">
        <GlobalIcon style="margin-right: 4px" />
        <ArrowDown />
      </div>
      <div v-if="isDropdownVisible" class="dropdown absolute">
        <p
          v-for="item in langList"
          :key="item.type"
          class="dropdown-item"
          @click="changeLanguage(item.locale)"
        >
          <span class="capitalize">
            {{ item.language }}
          </span>
          <TickCircle v-if="currentLang === item.locale" class="w-2 h-2" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalIcon from "@/components/icons/GlobalIcon";
import ArrowDown from "@/components/icons/CaretDownIcon";
import TickCircle from "@/components/icons/TickCircleIcon.vue";

export default {
  components: {
    GlobalIcon,
    ArrowDown,
    TickCircle,
  },
  data() {
    return {
      langList: [
        { type: "en", locale: "en", language: this.$t("English") },
        { type: "fr", locale: "fr", language: this.$t("French") },
      ],
      currentLang: this.$i18n.locale,
      isDropdownVisible: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    changeLanguage(lang) {
      console.log(
        this.$route.path === "/login",
        "042-429042942924-94242",
        lang
      );
      // if (this.$route.path === "/") {
      //   window.location.reload();
      // }
      localStorage.setItem("locale", lang);
      this.currentLang = lang;
      this.$i18n.locale = lang;
      this.isDropdownVisible = false;
    },
  },
};
</script>

<style scoped>
.toggle {
  max-height: 50px;
  display: flex;
  align-items: center;
}

.dropdown {
  max-height: 200px;
  overflow-y: auto;
  width: 110px;
  background-color: var(--background-color);
  color: var(--text-color);
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
  box-shadow: 0 2px 4px rgba(var(--sub-text-color), 0.8);
  position: absolute;
  top: 45px;
}

.dropdown-item {
  padding: 10px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  color: var(--text-color);
  cursor: pointer;
}
.dropdown-item:hover {
  background-color: var(--box-bg);
}
</style>
