import axios from "axios";

const stp = axios.create({
  baseURL: process.env.VUE_APP_STP_BASE_URL,
});

stp.defaults.headers.post["Content-Type"] = "application/json";
///stp.defaults.headers.post['x-stp-api-key'] = process.env.VUE_APP_STP_API_KEY;
stp.defaults.method = "post";

stp.interceptors.request.use(
  (config) => {
    const lang = localStorage.getItem("locale") || "en";
    config.headers["lang"] = lang;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default stp;
