<template>
  <div>
    <PlayJackpotFilter :tabs="tabs" @updateActive="handleActiveChange" />
    <section class="profile_links">
      <div class="load page_container" v-if="loading">
        <TransactionLoader :count="10" />
      </div>

      <div
        v-if="
          (!loading && !transaction) || (!loading && transaction.length < 1)
        "
        class="no_data"
      >
        <NoData
          :title="$t('noTransaction')"
          :description="$t('noDataForFilter')"
        />
      </div>
      <div v-if="!loading && transaction && transaction.length > 0">
        <div
          v-for="(item, index) in transaction"
          :key="index"
          class="transaction_wrap"
        >
          <div>
            <div class="box_one">
              <span v-if="item.transaction_type === 'Credit'">
                <CreditIcon />
              </span>
              <span v-else>
                <DebitIcon />
              </span>
              <div class="description">
                <h3>{{ item.description }}</h3>
                <p>
                  {{ item.created }}
                </p>
              </div>
            </div>
            <div class="box_two">
              <h3>
                {{ item.transaction_type === "Credit" ? "+" : "-" }}
                {{ fiatCurrency }}
                {{ item.amount }}
              </h3>
              <p>
                {{ fiatCurrency }} {{ item.balance + item.winning_balance }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="transaction && transaction.length > 0">
        <scroll-loader
          v-show="transaction.length > 0"
          :loader-method="loadOnScroll"
          :loader-disable="is_busy || page >= last_page"
        >
          <div></div>
        </scroll-loader>
      </div>
    </section>
  </div>
</template>

<script>
import { fetchTransactions } from "../../../actions/transactions";
import CreditIcon from "../../../components/icons/CreditIcon.vue";
import DebitIcon from "../../../components/icons/DebitIcon.vue";
import NoData from "../../../components/ui/NoData.vue";
import ScrollLoader from "vue-scroll-loader";
import Vue from "vue";
import TransactionLoader from "../../transactions/TransactionLoader.vue";
import PlayJackpotFilter from "../../play-jackpot/components/PlayJackpotFilter.vue";

Vue.use(ScrollLoader);

export default {
  name: "Payments",

  data: function () {
    return {
      tabs: [
        {
          label: "All",
          name: "all",
        },
        {
          label: "Commision",
          name: "commision",
        },
        {
          label: "Withdraws",
          name: "withdraws",
        },
      ],
      activeTab: 0,
      fiatCurrency: process.env.VUE_APP_CURRENCY,
      myProfile: this.getProfile(),
      loading: true,
      page: 1,
      per_page: 10,
      last_page: 1,
      start: "",
      end: "",
      sort: "created|desc",
      transaction_type: 0,
      reference_id: 0,
      currency: process.env.VUE_APP_CURRENCY,
      is_busy: false,
      transaction: [],
      hasMore: true,
    };
  },
  components: {
    TransactionLoader,
    CreditIcon,
    DebitIcon,
    // Dropdown,
    NoData,
    PlayJackpotFilter,
  },
  methods: {
    handleActiveChange(activeValue) {
      this.activeTab = activeValue;
    },
    loadOnScroll: function () {
      var vm = this;
      if (vm.transaction) {
        vm.myTransactions();
      }
    },
    async myTransactions() {
      const vm = this;
      var m = this.getProfile();
      var p = m.a;
      if (vm.is_busy) return;

      if (parseInt(vm.page) > parseInt(vm.last_page)) {
        vm.is_busy = false;
        return;
      }
      vm.is_busy = true;
      if (!p) {
        this.$router.push({ name: "login", params: {} });
        return;
      }

      try {
        const transactions = await fetchTransactions({
          page: this.page,
          per_page: this.per_page,
          start: this.start,
          end: this.end,
          sort: this.sort,
          transaction_type: this.transaction_type,
          reference_id: this.reference_id,
          apiKey: this.getAuth(),
        });
        if (transactions?.data === null) {
          vm.transaction = null;
        }
        if (transactions && Array.isArray(transactions.data)) {
          if (transactions?.data?.length > 0) {
            vm.last_page = transactions.last_page;
            if (parseInt(vm.page) === 1) {
              vm.transaction = [];
            }
            vm.transaction.push(...transactions.data);
            vm.page = parseInt(vm.page) + 1;
          } else {
            vm.is_busy = false;
            return;
          }
        } else {
          vm.transaction = [];
          vm.is_busy = false;
          return;
        }
        vm.is_busy = false;
      } catch (error) {
        this.loading = false;
        // if ([401, 400, 428].includes(error?.response?.status)) {
        //   this.setError(`${this.$t("sessionExpired")}`);
        //   this.logout();
        //   return;
        // }
        // this.setError(error?.response?.data?.message);
        this.handleFetchError(error);
      } finally {
        this.loading = false;
      }
    },
    handleFetchError(error) {
      this.loadingGames = false;
      if (error.response) {
        if (parseInt(error.response.data.status) === 401) {
          this.setError(`${this.$t("sessionExpired")}`);
          this.logout();
          return;
        }
        this.setError(error.response.data.error_message);
      }
    },
  },
  computed: {
    // profile: function () {
    //   return this.getProfile();
    // },
    profile: function () {
      return this.myProfile;
    },
  },
  watch: {
    transaction_type() {
      this.page = 1;
      this.loading = true;
      this.myTransactions();
    },
    reference_id() {
      this.loading = true;
      this.page = 1;
      this.myTransactions();
    },
    start() {
      this.loading = true;
      this.page = 1;
      this.myTransactions();
    },
    end() {
      this.loading = true;
      this.page = 1;
      this.myTransactions();
    },
  },

  mounted() {
    if (!this.myProfile) {
      this.setError("Login", this.$t("pleaseLoginProceed"));
      this.$router.push({ name: "login", params: {} });
      return;
    }
    this.myTransactions();
  },
};
</script>

<style src="./payments.css" scoped></style>
