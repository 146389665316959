<template>
  <section class="faq mb-3">
    <div
      class="text-dark accordion"
      data-toggle="collapse"
      data-target="#1"
      aria-expanded="false"
      aria-controls="1"
      @click="toggleCollapse('1')"
    >
      <strong>{{ $t("verifyingAccountNeccessary") }}</strong>
      <span
        v-html="collapsedSections['1'] ? arrowUpIcon : arrowDownIcon"
      ></span>
    </div>

    <div
      style="
        background-color: var(--slide-fill);
        padding: 15px;
        border-top: dotted 1px black;
      "
      class="promo-content text-dark collapse"
      id="1"
    >
      {{ $t("verifyingAccountNeccessaryAns") }}
    </div>
  </section>
</template>

<script>
export default {
  name: "Faq",
  data() {
    const totalSections = 27;
    const collapsedSections = {};

    for (let i = 1; i <= totalSections; i++) {
      collapsedSections[`${i}`] = false;
    }
    return {
      sections: [
        {
          title: this.$t("verifyingAccountNeccessary"),
          content: this.$t("verifyingAccountNeccessaryAns"),
        },
      ],
      collapsedSections,
      isCollapsed: true,
      arrowDownIcon: `
      <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.6 7.46L11.17 12.89a1.5 1.5 0 01-2.34 0L3.4 7.46" stroke="var(--sub-text-color)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      `,
      arrowUpIcon: `
        <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.3999 12.543L8.83324 7.10966C9.4749 6.468 10.5249 6.468 11.1666 7.10966L16.5999 12.543"
            stroke="var(--sub-text-color)"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      `,
    };
  },
  methods: {
    toggleCollapseFirstSection() {
      this.isCollapsed = !this.isCollapsed;
    },
    toggleCollapse(sectionId) {
      if (sectionId) {
        this.$set(
          this.collapsedSections,
          sectionId,
          !this.collapsedSections[sectionId]
        );
      }
    },
  },
};
</script>

<style src="./index.css" scoped></style>
