<template>
  <section class="shimmer-section">
    <div
      v-for="index in 10"
      :key="index"
      class="shimmer-wrapper box shine"
    ></div>
  </section>
</template>
<style scoped>
.shimmer-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  padding: 18px 20px;
}
.shine {
  background-color: var(--fixture-border);
  animation: pulse 1.5s infinite;
  /* background-image: linear-gradient(
    to right,
    var(--light-gray) 0%,
    #23313d 20%,
    var(--light-gray) 40%,
    var(--light-gray) 100%
  ); */
  /* background-repeat: no-repeat; */
  /* background-size: 800px 135px; */
  display: inline-block;
  position: relative;
}
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (min-width: 450px) {
  .shimmer-section {
    grid-template-columns: repeat(auto-fill, minmax(106.33px, 1fr)) !important;
  }
}
.box {
  min-height: 113px;
  width: 100%;
  border-radius: 5px;
}
.header-box {
  height: 100px;
  width: 100%;
  border-radius: 5px;
}

.lines {
  height: 10px;
  width: 100%;
}
.line-competition-left {
  width: 60%;
}
.line-competition-right {
  width: 60%;
  margin-right: 0px;
}
.line-team-name {
  width: 50%;
  height: 15px;
  margin-bottom: 3px;
}

.line-team-markets {
  width: 20%;
  height: 10px;
  margin-bottom: 3px;
}
photo {
  display: block !important;
  width: 325px;
  height: 100px;
  margin-top: 15px;
}
</style>
<script>
export default {
  name: "ShimmerCasino",
};
</script>
