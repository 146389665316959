<template>
  <div class="promo-wrapper">
    <div v-if="!selectedPromotion">
      <div class="select-sport">
        <button
          :class="{ active: activeCategory === 'all' }"
          @click="setCategory('all')"
        >
          {{ $t("all") }}
        </button>
        <button
          :class="{ active: activeCategory === 'sports' }"
          @click="setCategory('sports')"
        >
          {{ $t("sports") }}
        </button>
        <button
          :class="{ active: activeCategory === 'casino' }"
          @click="setCategory('casino')"
        >
          {{ $t("games") }}
        </button>
      </div>
      <div style="background-color: var(--background-color)" class="promotions">
        <div
          v-for="(promotion, index) in filteredMenuLinks"
          :key="index"
          class="card"
        >
          <div class="card-top">
            <img :src="getImageSrc(promotion.img)" alt="Promotion Image" />
          </div>
          <div class="card-center">
            <span>{{ $t(promotion.category) }}</span>
          </div>
          <div class="content">
            <p class="first-deposit">{{ $t(promotion.description) }}</p>
            <span>{{ $t(promotion.details) }}</span>
            <div class="btn">
              <button class="outline" @click="selectPromotion(promotion)">
                {{ $t("learnMore") }}
              </button>
              <button @click="goToJackpot(promotion)">
                {{ $t("betNow") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bettingserve from "../../services/bettingserve";

export default {
  name: "Gifts",

  data() {
    return {
      activeCategory: "all",
      activeDetail: false,
      selectedPromotion: null,
      balance: 0,
    };
  },
  computed: {
    promotions() {
      const items = this.$store.state.promotions;
      console.log(items, "itemitmiemitmimimimmimimimimimimmiiimmimim");
      return items;
    },
    balanceText() {
      return this.balance === 0 ? 0 : 1;
    },
    currentLanguage() {
      return this.$i18n.locale;
    },
    filteredMenuLinks() {
      let filteredLinks =
        this.activeCategory === "all"
          ? this.promotions
          : this.promotions.filter(
              (promotion) => promotion.category === this.activeCategory
            );

      filteredLinks = filteredLinks.filter((link) =>
        !this.isLoggedIn
          ? link
          : !(link.route === "/free-bet" && this.balance === 0)
      );
      return filteredLinks;
    },
    isLoggedIn: function () {
      var p = this.getProfile();
      if (!p) {
        return false;
      }
      return true;
    },
  },
  methods: {
    getImageSrc(image) {
      return image[this.currentLanguage] || image.fr;
    },
    setCategory(category) {
      this.activeCategory = category;
    },
    selectPromotion(promotion) {
      this.$router.push(`/gifts/${promotion.id}`);
      this.activeDetail = true;
    },
    closePromotion() {
      this.$router.push(`/gifts`);
    },
    goToJackpot(promotion) {
      if (promotion.category === "casino") {
        this.$router.push(promotion.route);
      } else if (promotion.route) {
        this.$router.push(this.isLoggedIn ? promotion.route : "/join");
      } else {
        this.$router.push("/jackpot");
      }
    },
    async getFreeBalance() {
      try {
        const path = `${process.env.VUE_APP_BASE_FREEBET_URL}/balance`;
        const response = await bettingserve.get(path, {
          headers: {
            "api-key": this.getAuth(),
          },
        });
        const todayDate = new Date();
        const expiryDate = new Date(response.data.expiry_date);
        if (
          response.data.balance !== 0 &&
          expiryDate.getTime() >= todayDate.getTime()
        ) {
          this.balance = response.data.balance;
        }
      } catch (error) {
        if (error.response) {
          if ([401, 400, 428].includes(error.response.status)) {
            // this.setError(`${this.$t("sessionExpired")}`);
            // this.logout();
          } else {
            this.setError(error.response.data.message);
          }
        } else {
          // this.setError("An error occurred while fetching data. Please try again later.");
        }
      } finally {
        this.balanceLoading = false;
        this.loading = false;
      }
    },

    handlePlayGame() {
      console.log(this.promotion, "this.promotionthis.promotionthis.promotion");
    },
  },
  mounted: function () {
    this.getFreeBalance();
  },
  watch: {
    currentLanguage() {
      console.log("first", this.promotions);
    },
  },
};
</script>

<style scoped src="./index.css"></style>
